import { LOADING_STATES } from 'src/utils/maps';
import { produce } from 'immer';
import { MY_CHILDREN_CONSTANTS } from 'src/modules/my-posts/constants/my-posts';

const initialState = {
  data: [],
  status: LOADING_STATES.LOADING,
  error: null,
  page: 0,
  firstLoad: true,
  isMore: true,
};

const myPostsReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case MY_CHILDREN_CONSTANTS.setMyPosts:
      draft.data = [...state.data, ...action.payload];
      break;
    case MY_CHILDREN_CONSTANTS.setPage:
      draft.page = action.payload;
      break;
    case MY_CHILDREN_CONSTANTS.setStatus:
      draft.status = action.payload;
      break;
    case MY_CHILDREN_CONSTANTS.setFirstLoad:
      draft.firstLoad = action.payload;
      break;
    case MY_CHILDREN_CONSTANTS.setIsMore:
      draft.isMore = action.payload;
      break;
    case MY_CHILDREN_CONSTANTS.resetMyPosts:
      return initialState;
    default:
      break;
  }
});

export default myPostsReducer;
