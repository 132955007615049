import { proxyHandler } from 'src/utils/redux';

const prefix = '@myPosts';

const constants = {
  setMyPosts: 'setMyPosts',
  resetMyPosts: 'resetMyPosts',
  setPage: 'setPage',
  setStatus: 'setStatus',
  setFirstLoad: 'setFirstLoad',
  setIsMore: 'setIsMore',
};

export const MY_CHILDREN_CONSTANTS = new Proxy(constants, proxyHandler(prefix));
