import { apiFetch, source } from 'src/modules/common/api';
import { getBearer } from 'src/utils/api';
import { getCountryIso } from 'src/modules/challenges/api/weekly';
import { TOURNAMENT_STATUS_MAP } from 'src/modules/tournaments/constants/tournamentStatusMap';

export const postTournamentGameSlideIdPostId = (slideId, postId) => apiFetch(`${source}/tournament-game/${slideId}/posts/${postId}`, {
  method: 'POST',
  headers: {
    ...getBearer(),
  },
});

/**
 * GET /tournament
 *
 * @param offset {number}
 * @param limit {number}
 * @param level_type_id {string}
 * @param status {string}
 */
export const getTournaments = (offset = 0, limit = 10, level_type_id = 'level_1', status = 'tournaments') => {
  let query = `offset=${offset}&limit=${limit}&status=${TOURNAMENT_STATUS_MAP[status]}`;
  const iso = getCountryIso();

  if (level_type_id !== 'all_levels' && level_type_id !== 'entry') {
    const level = level_type_id.split('_');
    // TODO: change to valid level, without summary
    query += `&level_type_id=${+level[1]}`;
  }

  if (level_type_id === 'entry') {
    query += '&level_type_id=5';
  }

  if (status === 'winnings') {
    query += '&me';
  }

  return apiFetch(`${source}/tournament?${query}`, {
    method: 'GET',
    headers: {
      ...getBearer(),
      'Content-Type': 'application/json',
      'x-country': iso,
    },
  });
};

const TOURNAMENT_HISTORY_MAP = {
  weekly: 'week',
  monthly: 'month',
};

/**
 * GET /tournament/leaderboard-history
 *
 * @param offset {number}
 * @param limit {number}
 * @param level_type_id {string}
 */
export const getTournamentsHistory = (offset = 0, limit = 10, level_type_id = 'weekly') => {
  let query = `offset=${offset}&limit=${limit}`;

  if (level_type_id !== 'all_time') {
    query += `&type=${TOURNAMENT_HISTORY_MAP[level_type_id]}`;
  }

  const iso = getCountryIso();

  return apiFetch(`${source}/tournament/leaderboard-history?${query}`, {
    method: 'GET',
    headers: {
      ...getBearer(),
      'Content-Type': 'application/json',
      'x-country': iso,
    },
  });
};

/**
 * GET /tournament/{tournamentId} && GET /tournament/{tournamentId}/leaderboard
 *
 * @param tournamentId {number}
 * @param leaderboard {boolean}
 */
export const getTournamentById = (tournamentId, leaderboard) => {
  const iso = getCountryIso();

  return apiFetch(`${source}/tournament/${tournamentId}${leaderboard ? '/leaderboard' : ''}`, {
    method: 'GET',
    headers: {
      ...getBearer(),
      'x-country': iso,
    },
  });
};

/**
 * GET /tournament/{id}/posts
 *
 * @param tournamentId {number}
 */
export const getTournamentPosts = (tournamentId) => {
  const iso = getCountryIso();

  return apiFetch(`${source}/tournament/${tournamentId}/posts`, {
    method: 'GET',
    headers: {
      ...getBearer(),
      'x-country': iso,
    },
  });
};

/**
 *
 * @param tournamentId {number}
 * @param body {object}
 * @param {number} body.paymentMethodId
 * @param {number} body.post_id
 * @param {string} body.timezone
 * @param [body.card] {object}
 * @param [body.saved] {object}
 * @param {string} body.card.number
 * @param {string} body.card.cvv
 * @param {boolean} body.card.save
 * @param {object} body.card.expiration
 * @param {number} body.card.expiration.month
 * @param {string} body.card.expiration.year
 * @param {string} body.saved.fingerprint
 */
export const tournamentPayForParticipation = (tournamentId, body) => {
  const iso = getCountryIso();

  return apiFetch(`${source}/tournament/${tournamentId}/pay-for-participation`, {
    method: 'POST',
    headers: {
      ...getBearer(),
      'x-country': iso,
    },
    body: JSON.stringify(body),
  });
};

/**
 * GET /tournament-game/cards
 */
export const getTournamentGameCards = () => {
  const iso = getCountryIso();

  return apiFetch(`${source}/tournament-game/cards`, {
    method: 'GET',
    headers: {
      ...getBearer(),
      'x-country': iso,
    },
  });
};

/**
 *
 * @param slideId {number}
 * @param type {'first'|'second'|'view'}
 */
export const patchTournamentGameCard = (slideId, type) => {
  const iso = getCountryIso();

  return apiFetch(`${source}/tournament-game/${slideId}/${type}`, {
    method: 'PATCH',
    headers: {
      ...getBearer(),
      'x-country': iso,
    },
  });
};

/**
 * PATCH /tournament/save-popup-preview
 */
export const patchSavePopupPreview = () => apiFetch(`${source}/tournament/save-popup-preview`, {
  method: 'PATCH',
  headers: {
    ...getBearer(),
  },
});

/**
 * PATCH /tournament/save-popup-video-preview
 */
export const patchPopupVideoPreview = () => apiFetch(`${source}/tournament/save-popup-video-preview`, {
  method: 'PATCH',
  headers: {
    ...getBearer(),
  },
});

/**
 * GET /tournament/get-notification
 */
export const getTournamentNotification = () => apiFetch(`${source}/tournament/get-notification`, {
  method: 'GET',
  headers: {
    ...getBearer(),
  },
});
