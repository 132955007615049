import { combineReducers } from 'redux';
import paymentsHistory from 'src/reducers/paymentsHistory';
import withdraw from 'src/reducers/withdraw';
import tournaments from 'src/modules/tournaments/reducers/tournaments';
import tournamentGame from 'src/modules/tournaments/reducers/tournament-game';
import leaderboard from 'src/modules/contest/reducers/leaderboard';
import contest from 'src/modules/contest/reducers/contest';
import myPosts from 'src/modules/my-posts/reducers/my-posts';
import challenges from 'src/modules/challenges/reducers/challenges';
import challenge from 'src/modules/challenges/reducers/challenge';
import childrenPhotos from 'src/modules/photos/reducers/posts-photos';
import posts from './posts';
import app from './app';
import post from './post';
import search from './search';
import user from './user';
import referral from './referral';
import registration from './registration';
import comments from './comments';
import testimonials from './testimonials';
import achievements from './achievements';
import littleswipe from './littleswipe';
import deposit from './deposit';

export default combineReducers({
  posts,
  app,
  search,
  user,
  registration,
  comments,
  post,
  testimonials,
  achievements,
  littleswipe,
  childrenPhotos,
  challenges,
  challenge,
  deposit,
  paymentsHistory,
  withdraw,
  tournaments,
  tournamentGame,
  leaderboard,
  contest,
  myPosts,
  referral,
});
