import { proxyHandler } from 'src/utils/redux';

const prefix = '@postPhotos';

const constants = {
  SET_POSTS_PHOTOS_STATUS: 'setPostsPhotosStatus',
  SET_POSTS_PHOTOS_IS_LOADED: 'setPostsPhotosIsLoaded',
  SET_POSTS_PHOTOS_PAGE: 'setPostsPhotosPage',
  SET_POSTS_PHOTOS_DATA: 'setPostsPhotosData',
  SET_POSTS_PHOTOS_ERROR: 'setPostsPhotosError',
  SET_POSTS_IS_MORE: 'setPostsPhotosIsMore',
  SET_POSTS_PHOTOS_META: 'setPostsPhotosMeta',
  RESET_POSTS_PHOTOS: 'reset',
};

const POSTS_PHOTOS_CONSTANTS = new Proxy(constants, proxyHandler(prefix));

export default POSTS_PHOTOS_CONSTANTS;
