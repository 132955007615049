import React, { Component } from 'react';
import io from 'socket.io-client';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';

class SocketLoader extends Component {
  socket = null;

  isOnPupswipe = false;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.login !== this.props.login && this.socket) {
      this.socket.off('achievement.unlocked', this.achievementHandler);
      this.socket = null;
      return;
    }

    if (!this.socket && this.props.login) {
      const userData = localStorage.getItem('userData');

      const json = JSON.parse(userData);
      const socket = io(
        process.env.REACT_APP_PRODUCTION === 'TRUE'
          ? 'https://ws.littlewinner.com'
          : 'https://ws-littlewinner.abz.dev',
      );
      this.socket = socket;

      socket.emit('polling', { token: json.apiToken });

      socket.on('disconnect', (reason) => {
        console.log(reason);
      });

      socket.on('achievement.unlocked', this.props.achievementHandler);

      socket.on('connect_error', () => {
        setTimeout(() => {
          socket.connect();
        }, 5000);
      });

      socket.on('littleswipe.session.permission', (payload) => {
        // console.log('session permission', payload);
      });

      socket.on('error', (error) => {
        console.log(error);
      });
    }
  }

  render() {
    return <div />;
  }
}

function mapStateToProps(state) {
  const { login, apiToken, id } = state.user;

  return {
    login,
    apiToken,
    id,
  };
}

SocketLoader.propTypes = {
  achievementHandler: Function,
};

export default connect(
  mapStateToProps,
  null,
)(injectIntl(withRouter(SocketLoader)));
