import {
  SET_ACHIEVEMENTS_DATA,
  SET_ACHIEVEMENTS_STATE,
  UPDATE_ACHIEVEMENTS_DATA,
  RECEIVED_ACHIEVEMT,
} from '../actionTypes';
import { newState } from '../utils';

const initialState = {
  data: null,
  pageState: 'LOADING', // LOADING | LOADED | ERROR
};

const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACHIEVEMENTS_DATA:
      return newState(state, {
        data: action.payload,
      });
    case SET_ACHIEVEMENTS_STATE:
      return newState(state, {
        pageState: action.payload,
      });
    case RECEIVED_ACHIEVEMT:
      if (!state.data) return state;

      return newState(state, {
        data: state.data.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              isUnlocked: true,
            };
          }
          return item;
        }),
      });
    case UPDATE_ACHIEVEMENTS_DATA:
      if (!state.data) return state;

      return newState(state, {
        data: state.data.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              isSpent: true,
            };
          }
          return item;
        }),
      });
    default:
      return state;
  }
};

export default postsReducer;
