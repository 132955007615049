import EXIF from 'exif-js';
import moment from 'moment';

/**
 * Does the item exist in the viewport
 * @param target {HTMLElement} target element
 * @param offsetTop {int} top offset of the target element
 * @return {boolean}
 */
export const isVisible = (target, offsetTop = 0) => {
  if (!target) return false;
  const targetPosition = {
    top: window.pageYOffset + target.getBoundingClientRect().top - offsetTop,
    left: window.pageXOffset + target.getBoundingClientRect().left,
    right: window.pageXOffset + target.getBoundingClientRect().right,
    bottom: window.pageYOffset + target.getBoundingClientRect().bottom,
  };
  const windowPosition = {
    top: window.pageYOffset,
    left: window.pageXOffset,
    right: window.pageXOffset + document.documentElement.clientWidth,
    bottom: window.pageYOffset + document.documentElement.clientHeight,
  };
  if (
    targetPosition.bottom > windowPosition.top
    && targetPosition.top < windowPosition.bottom
    && targetPosition.right > windowPosition.left
    && targetPosition.left < windowPosition.right
  ) {
    return true;
  }
};

export const formatNameWithId = (name, id) => `${name
  .replace(/[ _/&=?!№@/[$%^#*()'";`/\-=\\:+,.|}{\]]/g, '-')
  .toLowerCase()
  .replace(/-+/g, '-')}_${id}`;

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

export const splitArrayToChunk = (array, chunks) => {
  if (array) {
    return array.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / chunks);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);
  }

  return [];
};

export const debounce = (func, wait, immediate) => {
  try {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      const later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };

      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  } catch (e) {
    console.log(e);
  }
};

export const throttled = (delay, fn) => {
  let lastCall = 0;
  return (...args) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }

    lastCall = now;
    return fn(...args);
  };
};

export const getParameterByName = (name, url) => {
  let currentURL = url;
  if (!currentURL) {
    currentURL = window.location.href;
  }

  const currentName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${currentName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(currentURL);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const searchFactory = (data, requestName) => {
  let subheading = '';
  const newData = data.map((obj) => {
    // Set country by country code
    if (obj.location) {
      subheading = obj.location;
    } else {
      subheading = obj.gender;
    }

    return {
      imageUrl: obj.image,
      heading: obj.nickname || obj.name,
      subheading,
      href: obj.gender
        ? `/detail/${formatNameWithId(obj.name, obj.id)}`
        : '/',
    };
  });

  let type = 'users';
  if (requestName === 'posts') {
    type = 'posts';
  }

  return {
    data: newData,
    type,
  };
};

export const deepCompare = (o1, o2) => {
  for (const p in o1) {
    if (o1.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false;
      }
    }
  }

  for (const p in o2) {
    if (o2.hasOwnProperty(p)) {
      if (o1[p] !== o2[p]) {
        return false;
      }
    }
  }

  return true;
};

export function checkFormat(image) {
  const extension = image.name.split('.').pop();
  return !(
    extension.toLowerCase() !== 'jpeg'
    && extension.toLowerCase() !== 'jpg'
    && extension.toLowerCase() !== 'png'
  );
}

export function checkSize(image, size = 5) {
  return image.size / 1024 / 1024 < size;
}

export const getImageResolution = (image) => {
  const promise = new Promise((resolve, reject) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(image);

    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      window.URL.revokeObjectURL(img.src);

      resolve({ width, height });
    };

    img.onerror = () => {
      resolve('Damaged file');
    };
  });

  return promise;
};

export async function checkResolution(image, maxWidth, maxHeight) {
  const promise = new Promise((resolve, reject) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(image);

    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      window.URL.revokeObjectURL(img.src);

      resolve(!(width < (maxWidth || 800) || height < (maxHeight || 800)));
    };

    img.onerror = () => {
      resolve('Damaged file');
    };
  });

  return promise;
}

export const toInt = (num) => Number(String(num).split('.').join(''));

export const orientateCanvas = (context, img, orientation) => {
  context.canvas.width = img.naturalWidth;
  context.canvas.height = img.naturalHeight;
  switch (orientation) {
    case 2:
      // horizontal flip
      context.translate(context.canvas.width, 0);
      context.scale(-1, 1);
      break;
    case 3:
      // 180° rotate left
      context.translate(context.canvas.width, context.canvas.height);
      context.rotate(Math.PI);
      break;
    case 4:
      // vertical flip
      context.translate(0, context.canvas.height);
      context.scale(1, -1);
      break;
    case 5:
      // vertical flip + 90 rotate right
      context.canvas.height = img.naturalWidth;
      context.canvas.width = img.naturalHeight;
      context.rotate(0.5 * Math.PI);
      context.scale(1, -1);
      break;
    case 6:
      // 90° rotate right
      context.canvas.height = img.naturalWidth;
      context.canvas.width = img.naturalHeight;
      context.rotate(0.5 * Math.PI);
      context.translate(0, -context.canvas.width);
      break;
    case 7:
      // horizontal flip + 90 rotate right
      context.rotate(0.5 * Math.PI);
      context.canvas.height = img.naturalWidth;
      context.canvas.width = img.naturalHeight;
      context.translate(context.canvas.height, -context.canvas.width);
      context.scale(-1, 1);
      break;
    case 8:
      // 90° rotate left
      context.canvas.height = img.naturalWidth;
      context.canvas.width = img.naturalHeight;
      context.rotate(-0.5 * Math.PI);
      context.translate(-context.canvas.height, 0);
      break;
  }
  context.drawImage(img, 0, 0);
  return context;
};

export const getImageOrientation = (img) => new Promise((resolve) => {
  let result = null;
  EXIF.getData(img, function () {
    const orientation = EXIF.getTag(this, 'Orientation');
    let context = document.createElement('canvas').getContext('2d');
    context = orientateCanvas(context, img, orientation);
    result = context.canvas.toDataURL();
    resolve(result);
  });
  // return result;
});

export const formatTime = (date, locale = 'en') => {
  const language = locale === 'no' ? 'nb' : locale;
  return moment(date).locale(language).fromNow(true);
};

export const getErrorMessage = (error) => {
  let errorMes = '';
  for (const errorMessage in error) {
    errorMes += ` ${error[errorMessage]}`;
  }

  return errorMes;
};

export const copyToClipboard = (string, target) => {
  let textarea;
  let result;

  try {
    textarea = document.createElement('textarea');
    textarea.setAttribute('readonly', true);
    textarea.setAttribute('contenteditable', true);
    textarea.style.position = 'fixed';
    textarea.value = string;

    if (target) {
      document.querySelector(target).appendChild(textarea);
    } else {
      document.body.appendChild(textarea);
    }

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    // console.log(textarea);
    result = document.execCommand('copy');
    // console.log(result);
  } catch (err) {
    console.log(err);
    result = null;
  } finally {
    if (target) {
      document.querySelector(target).removeChild(textarea);
    } else {
      document.body.removeChild(textarea);
    }
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C';
    result = prompt(`Press ${copyHotkey}`, string); // eslint-disable-line no-alert
    if (!result) {
      return false;
    }
  }

  return true;
};

export const retry = (fn, retriesLeft = 5, interval = 1000) => new Promise((resolve, reject) => {
  fn()
    .then(resolve)
    .catch((error) => {
      setTimeout(() => {
        if (retriesLeft === 1) {
          // reject('maximum retries exceeded');
          reject(error);
          return;
        }

        // Passing on "reject" is the important part
        retry(fn, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
});

export const formatNumberVote = (vote) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K+' },
    { value: 1e6, symbol: 'M+' },
    { value: 1e9, symbol: 'G+' },
    { value: 1e12, symbol: 'T+' },
    { value: 1e15, symbol: 'P+' },
    { value: 1e18, symbol: 'E+' },
  ];

  const decimalRegExpToHalf = /\.[6789]/;
  const decimalRegExpToZero = /\.[01234]/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (vote >= si[i].value) {
      break;
    }
  }

  return (
    (vote / si[i].value).toFixed(1).replace('.0', '') + si[i].symbol
    // .slice(0, -1)
    // .replace(decimalRegExpToHalf, '.5')
    // .replace(decimalRegExpToZero, '') + si[i].symbol
  );
};

const thMap = {
  fr: {
    st: 'er',
    nd: 'e',
    rd: 'e',
    th: 'ème',
  },
  se: {
    st: 'a',
    nd: 'a',
    rd: 'e',
    th: 'e',
  },
  fi: {
    st: '',
    nd: '',
    rd: '',
    th: '',
  },
  no: {
    st: '',
    nd: '',
    rd: '',
    th: '',
  },
};

export const formatNumberToTh = (num, locale) => {
  const number = String(Number(num));
  const translated = thMap[locale];

  if (Number(number[number.length - 1]) === 1 && number !== '11') {
    return translated ? translated.st : 'st';
  }

  if (Number(number[number.length - 1]) === 2 && number !== '12') {
    return translated ? translated.nd : 'nd';
  }

  if (Number(number[number.length - 1]) === 3 && number !== '13') {
    return translated ? translated.rd : 'rd';
  }

  return translated ? translated.th : 'th';
};

export const emailValidation = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const convertedEmail = String(email).toLowerCase();
  return re.test(convertedEmail);
};

export const getCardImageSize = () => {
  const wWitdh = window.innerWidth;
  let imageSize = '_480x480.jpg';
  if (wWitdh <= 760 && wWitdh > 511) {
    imageSize = '_640x640.jpg';
  }

  return imageSize;
};

export const cutName = (name) => {
  const cuttedName = name.replace(/ +/g, ' ');
  const allName = cuttedName.split(' ');
  const firstName = allName[0];
  const secondName = allName[1] ? `${allName[1][0]}.` : '';
  return `${firstName} ${secondName}`;
};

export const initialScript = (scriptSRC, callback, id) => {
  const head = document.querySelector('head');
  const script = document.createElement('script');
  script.setAttribute('src', scriptSRC);
  script.setAttribute('id', id);
  script.addEventListener('load', () => {
    callback();
  });
  head.appendChild(script);
};

export const has = (object, key) => (object ? hasOwnProperty.call(object, key) : false);

export const pageFormatter = (page = -1) => {
  return 0;
  if (page < -7) {
    return -(-7 - (page % 7));
  }
  return 7 - (page + 1);
};
// Determines if the user is likely using an ad block extension
export const checkAdBlocker = async () => {
  // Used to cache the result
  let isBlocked;

  async function tryRequest() {
    try {
      return fetch(
        new Request(
          'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
          {
            method: 'HEAD',
            mode: 'no-cors',
          },
        ),
      )
        .then((response) => {
          // Google Ads request succeeded, so likely no ad blocker
          isBlocked = false;
          return isBlocked;
        })
        .catch((e) => {
          // Request failed, likely due to ad blocker
          isBlocked = true;
          return isBlocked;
        });
    } catch (error) {
      // fetch API error; possible fetch not supported (old browser)
      // Marking as a blocker since there was an error and so
      // we can prevent continued requests when this function is run
      console.log(error);
      isBlocked = true;
      return isBlocked;
    }
  }

  return isBlocked !== undefined ? isBlocked : await tryRequest();
};

export const perPageController = (width) => {
  if (width >= 1320) {
    return 16;
  }
  if (width <= 998) {
    return 12;
  }
  if (width <= 1320) {
    return 16;
  }
};

export const isDevice = (device) => Boolean(
  device.includes('iphone')
      || device.includes('ipad')
      || device.includes('android')
      || device.includes('ios'),
);

export function isIpadOS() {
  return (
    navigator.maxTouchPoints
    && navigator.maxTouchPoints > 2
    && /MacIntel/.test(navigator.platform)
  );
}

export const isIpad = () => /iPad/.test(navigator.userAgent) && !window.MSStream;

export const newState = (state, newStateField) => ({
  ...state,
  ...newStateField,
});

export const cutSpaces = (val) => val.replace(/\s\s+/g, ' ').trim();

export const cutDoubleSpaces = (val) => val.replace(/\n{2,}/g, '\n\n');

export const formatString = (val) => cutSpaces(cutDoubleSpaces(val));

export const disableScroll = () => {
  const body = document.querySelector('body');
  body.style.overflow = 'hidden';
  body.style.position = 'fixed';
  body.style.width = '100%';
};

export const enableScroll = () => {
  const body = document.querySelector('body');
  body.style.removeProperty('overflow');
  body.style.removeProperty('position');
  body.style.removeProperty('top');
  body.style.removeProperty('width');
  body.setAttribute('style', '');
};

export function openInNewTab(url) {
  const win = window.open(url, '_blank');

  if (win) {
    win.focus();
  }

  if (win) {
    win.focus();
  }
}

export function generateQuickGuid() {
  return (
    Math.random().toString(36).substring(2, 15)
    + Math.random().toString(36).substring(2, 15)
  );
}

export const uniqueObjectsArray = (array, callback) => {
  const result = [];

  array.forEach((item) => {
    if (result.find((el) => el.id === item.id)) {
      return;
    }

    result.push(item);
  });

  return result;
};

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export const transformPrizeNumber = (prizeString) => {
  try {
    const [prize] = prizeString.split('.');

    return prize;
  } catch (e) {
    console.log(e);
    return prizeString;
  }
};

export const parseNumber = (string) => {
  try {
    return string.replace(/[^0-9]/g, '');
  } catch (e) {
    console.log(e);
    return string;
  }
};

export function insertUrlParam(key, value) {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

export function resetUrlParams() {
  if (window.history.pushState) {
    const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    window.history.pushState({ path: newurl }, '', newurl);
  }
}

export function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    // console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error('Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    () => {
      // console.log('Async: Copying to clipboard was successful!');
    },
    (err) => {
      console.error('Could not copy text: ', err);
    },
  );
}
