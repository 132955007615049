import {
  SET_POST_EDIT,
  SET_ADD_POST_CARD_INFO,
  SET_POST_AWARDS,
  SET_POST_PHOTOS,
  SET_ALL_POST_PHOTOS,
} from '../actionTypes';
import { newState } from '../utils';

const initialState = {
  postIsEditing: false,
  child_name: '',
  child_photo: '',
  croppedAreaPixels: {},
  imgSrc: '',
  awards: {
    data: [],
    status: 'LOADING',
    error: null,
  },
  photos: {
    data: [],
    meta: {},
    status: 'LOADING',
    error: null,
  },
  allPhotos: {
    data: [],
    meta: {},
    status: 'LOADING',
    error: null,
  },
};

const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POST_EDIT:
      return newState(state, {
        postIsEditing: action.payload,
      });
    case SET_ADD_POST_CARD_INFO:
      return newState(state, {
        child_name: action.payload.name,
        child_photo: action.payload.photo,
        imgSrc: action.payload.imgSrc,
        croppedAreaPixels: action.payload.croppedAreaPixels,
      });
    case SET_POST_AWARDS:
      return newState(state, {
        awards: {
          ...state.awards,
          ...action.payload,
        },
      });
    case SET_POST_PHOTOS:
      return newState(state, {
        photos: {
          ...state.photos,
          ...action.payload,
        },
      });
    case SET_ALL_POST_PHOTOS:
      return newState(state, {
        allPhotos: {
          ...action.payload,
        },
      });
    default:
      return state;
  }
};

export default postsReducer;
