import { apiFetch, source } from 'src/modules/common/api';
import { store } from 'src/index';

export const getCountryIso = () => {
  try {
    const { app } = store.getState();

    if (app) {
      return app.currentCountry.code.toLowerCase();
    }

    return 'us';
  } catch (e) {
    console.log(e);
    return 'us';
  }
};

export const thematicContestsUploadPhoto = (formData) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/users/me/posts/photos/upload`, {
    method: 'POST',
    body: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${apiToken}`,
    },
  });
};

export const createPhotoForPostId = (body) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/users/me/posts/photos`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getUsersMePostsPhotos = (
  offset = 0,
  limit = 8,
  innerLimit = 7,
  withPhotos = 0,
  notInContest = 0,
) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/users/me/posts/photos?offset=${offset}&limit=${limit}&inner_limit=${innerLimit}&with_photos=${withPhotos}&not_in_contest=${notInContest}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getPostPhotosById = (
  postId,
  offset = 0,
  innerOffset = 7,
  limit = 6,
) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/posts/${postId}/photos?offset=${offset}&limit=${limit}&innerOffset=${innerOffset}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getThematicContests = (offset, limit = 16) => {
  const iso = getCountryIso();

  return apiFetch(
    `${source}/thematic-contests?offset=${offset}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-country': iso,
      },
    },
  );
};

export const getClosedThematicContests = (offset, limit = 16) => {
  const iso = getCountryIso();

  return apiFetch(
    `${source}/thematic-contests/closed?offset=${offset}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-country': iso,
      },
    },
  );
};

export const getCurrentThematicContest = () => {
  const iso = getCountryIso();

  return apiFetch(`${source}/thematic-contests/current`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-country': iso,
    },
  });
};

export const participateByPostIdAndPhotoId = (postId, photoId) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/users/me/posts/${postId}/photos/${photoId}/participate`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getContestById = (contestId) => {
  const iso = getCountryIso();

  return apiFetch(`${source}/thematic-contests/${contestId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-country': iso,
    },
  });
};

export const getContestLeaderboardById = (contestId, offset, limit = 16) => {
  const iso = getCountryIso();

  return apiFetch(
    `${source}/thematic-contests/${contestId}/leaderboard?offset=${offset}&limit=${limit}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-country': iso,
      },
    },
  );
};

export const getPostPhotoById = (postId, photoId, id) => apiFetch(`${source}/posts/${postId}/photos/${photoId}${id ? `?contest_id=${id}` : ''}`, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getPostPhotoPackagesById = (postId, photoId) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;
  const iso = getCountryIso();

  return apiFetch(
    `${source}/posts/${postId}/photos/${photoId}/votes/packages`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
        'x-country': iso,
      },
    },
  );
};

export const voteForPhotoById = (postId, photoId) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;
  const iso = getCountryIso();

  return apiFetch(`${source}/posts/${postId}/photos/${photoId}/votes`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
      'x-country': iso,
    },
  });
};

export const updatePhotoByPostAndPhotoId = (postId, photoId, body) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/users/me/posts/${postId}/photos/${photoId}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const deletePhotoByPostAndPhotoId = (postId, photoId) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/users/me/posts/${postId}/photos/${photoId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getPostPrizesById = (postId) => apiFetch(`${source}/posts/${postId}/prizes?offset=0&limit=12`, {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const getMyPhotosForThematicContest = (contestId) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/thematic-contests/${contestId}/me/photos?offset=0&limit=50`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getThematicContestPhotoPlace = (contestId, photoId) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(
    `${source}/thematic-contests/${contestId}/me/photos/${photoId}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${apiToken}`,
        'Content-Type': 'application/json',
      },
    },
  );
};

export const getThematicContestForPostId = (postId) => {
  const userData = JSON.parse(window.localStorage.getItem('userData'));
  const apiToken = userData && userData.apiToken;

  return apiFetch(`${source}/users/me/posts/${postId}/thematic-contest`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${apiToken}`,
      'Content-Type': 'application/json',
    },
  });
};

export const getThematicContestWidget = () => {
  const iso = getCountryIso();

  return apiFetch(`${source}/thematic-contests/current/widget`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-country': iso,
    },
  });
};
