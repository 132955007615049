import {
  SET_GAME_ENABLED,
  SET_IS_BLOCKED,
  SET_PROGRESS,
  SET_LITTLESWIPE_DATA,
  SET_LITTLESWIPE_ERROR,
  SET_LITTLESWIPE_MORE,
  SET_LITTLESWIPE_PAGE,
  SET_LITTLESWIPE_STATUS,
  SET_LITTLESWIPE_TUTORIAL,
  SET_TOTAL_CARDS,
  SET_WHEEL_DATA,
  SET_LITTLESWIPE_CARD_REMOVE,
  SET_LITTLESWIPES_COUNT,
  RESET_LITTLESWIPE_WHEEL,
  SET_LITTLESWIPE_MODAL,
  RESET_LITTLESWIPE,
  SET_LITTLESWIPE_INDICATORS,
  SET_LITTLESWIPE_BLOCKED,
  SET_LITTLESWIPE_TIMELEFT,
} from '../actionTypes';
import { newState } from '../utils';

const initialState = {
  data: [],
  pageToLoad: 1,
  isMore: true,
  status: 'pending',
  error: null,
  withTutorial: true,
  wheelData: {
    active: false,
    items: [],
    spottedItem: null,
    littleswipeCardId: null,
  },
  progress: 0,
  totalCards: 0,
  gameEnabled: true,
  isBlocked: false,
  swipesCount: 0,
  modalOpened: false,
  timeForNextGame: null,
  isAdCard: false,
  indicators: {
    closestAd: null,
    closestWheel: null,
    progress: null,
    slidesLeft: null,
  },
};

const pupswipeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LITTLESWIPE_DATA:
      return newState(state, {
        data: action.payload,
        error: null,
        status: 'success',
        // pageToLoad: state.pageToLoad + 1,
      });
    case SET_LITTLESWIPE_CARD_REMOVE:
      return newState(state, {
        data: action.payload,
      });
    case SET_LITTLESWIPE_MORE:
      return newState(state, {
        isMore: action.payload,
      });
    case SET_LITTLESWIPE_STATUS:
      return newState(state, {
        status: action.payload,
      });
    case SET_LITTLESWIPE_PAGE:
      return newState(state, {
        pageToLoad: action.payload,
      });
    case SET_LITTLESWIPE_ERROR:
      return newState(state, {
        error: action.payload,
        status: 'error',
      });
    case SET_LITTLESWIPE_TUTORIAL:
      return newState(state, {
        withTutorial: action.payload,
      });
    case SET_WHEEL_DATA:
      return newState(state, {
        wheelData: {
          ...state.wheelData,
          ...action.payload,
        },
      });
    case SET_IS_BLOCKED:
      return newState(state, {
        isBlocked: action.payload,
      });
    case SET_PROGRESS:
      return newState(state, {
        progress: action.payload,
      });
    case SET_TOTAL_CARDS:
      return newState(state, {
        totalCards: action.payload,
      });
    case SET_GAME_ENABLED:
      return newState(state, {
        gameEnabled: action.payload,
      });
    case SET_LITTLESWIPE_BLOCKED:
      return newState(state, {
        isBlocked: action.payload,
      });
    case SET_LITTLESWIPES_COUNT:
      return newState(state, {
        swipesCount: state.swipesCount + action.payload,
      });
    case RESET_LITTLESWIPE_WHEEL:
      return newState(state, {
        swipesCount: 0,
        progress: 0,
      });
    case SET_LITTLESWIPE_MODAL:
      return newState(state, {
        modalOpened: action.payload,
      });
    case SET_LITTLESWIPE_INDICATORS:
      return newState(state, {
        indicators: {
          ...state.indicators,
          ...action.payload,
        },
      });
    case SET_LITTLESWIPE_TIMELEFT:
      return newState(state, {
        timeForNextGame: action.payload,
      });
    case RESET_LITTLESWIPE:
      return {
        ...initialState,
      };
    case 'littleswipe/setAdCard':
      return newState(state, {
        isAdCard: action.payload,
      });
    default:
      return state;
  }
};

export default pupswipeReducer;
