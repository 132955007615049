import React, { useEffect, useState } from 'react';
import { Provider, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { render } from 'react-dom';
import moment from 'moment';
import { initializeStore } from './store';
import App from './App';
import loadFont from './components/common/FontLoader';
import 'moment/locale/se';
import 'moment/locale/nb';
import 'moment/locale/fr';
import 'moment/locale/fi';
import { GoogleOAuthProvider } from '@react-oauth/google';

moment.locale('se', {
  months:
    'Januari_Februari_Mars_April_Maj_Juni_Juli_Augusti_September_Oktober_November_December'.split(
      '_',
    ),
  relativeTime: {
    m: '1 minuhta',
    h: '1 diimmu',
    d: '1 beaivi',
    M: '1 mánnu',
    y: '1 jahki',
  },
});

moment.locale('en', {
  months:
    'January_February_March_April_May_June_July_August_September_October_November_December'.split(
      '_',
    ),
  relativeTime: {
    m: '1 minute',
    h: '1 hour',
    d: '1 day',
    M: '1 month',
    y: '1 year',
  },
});

moment.locale('nb', {
  relativeTime: {
    m: '1 minutt',
    h: '1 time',
    d: '1 dag',
    M: '1 måned',
    y: '1 år',
  },
});

loadFont(
  'https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,500,600,700,900',
);

export const store = initializeStore();

const IntlApp = () => {
  const currentCountry = useSelector((state) => state.app.currentCountry);
  const [messages, setMessages] = useState();

  const locale = (currentCountry && currentCountry.language) || 'en';

  useEffect(() => {
    (async () => {
      switch (locale) {
        case 'en':
          setMessages(JSON.parse(JSON.stringify(await import(/* webpackChunkName: "en-locale" */ 'src/translations/locales/en.json'))).default);
          break;
        case 'se':
          setMessages(JSON.parse(JSON.stringify(await import(/* webpackChunkName: "se-locale" */ 'src/translations/locales/se.json'))).default);
          break;
        case 'no':
          setMessages(JSON.parse(JSON.stringify(await import(/* webpackChunkName: "no-locale" */ 'src/translations/locales/no.json'))).default);
          break;
        case 'fr':
          setMessages(JSON.parse(JSON.stringify(await import(/* webpackChunkName: "fr-locale" */ 'src/translations/locales/fr.json'))).default);
          break;
        case 'fi':
          setMessages(JSON.parse(JSON.stringify(await import(/* webpackChunkName: "fi-locale" */ 'src/translations/locales/fi.json'))).default);
          break;
        default:
          setMessages(JSON.parse(JSON.stringify(await import(/* webpackChunkName: "en-locale" */ 'src/translations/locales/en.json'))).default);
          break;
      }
    })();
  }, [locale]);

  return (
    <GoogleOAuthProvider clientId={
      process.env.REACT_APP_PRODUCTION === 'TRUE'
        ? process.env.REACT_APP_CLIENT_ID_GOOGLE_SDK_PROD
        : process.env.REACT_APP_CLIENT_ID_GOOGLE_SDK_DEV
    }
    >
      <IntlProvider locale={locale} messages={messages}>
        <App />
      </IntlProvider>
    </GoogleOAuthProvider>
  );
};

render(
  <Provider store={store}>
    <IntlApp />
  </Provider>,
  document.getElementById('root'),
);
