import { produce } from 'immer';
import POSTS_PHOTOS_CONSTANTS from 'src/modules/photos/constants/posts-photos';

const initialState = {
  status: 'LOADING',
  isLoaded: false,
  isMore: true,
  page: 0,
  data: [],
  error: null,
  meta: {
    offset: 0,
    limit: 8,
    total: 0,
    prevLink: null,
    nextLink: null,
  },
};

const childrenPhotosReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case POSTS_PHOTOS_CONSTANTS.SET_POSTS_PHOTOS_DATA:
      draft.data = action.payload;
      break;
    case POSTS_PHOTOS_CONSTANTS.SET_POSTS_PHOTOS_STATUS:
      draft.status = action.payload;
      break;
    case POSTS_PHOTOS_CONSTANTS.SET_POSTS_PHOTOS_IS_LOADED:
      draft.isLoaded = action.payload;
      break;
    case POSTS_PHOTOS_CONSTANTS.SET_POSTS_PHOTOS_PAGE:
      draft.page = action.payload;
      break;
    case POSTS_PHOTOS_CONSTANTS.SET_POSTS_PHOTOS_ERROR:
      draft.error = action.payload;
      break;
    case POSTS_PHOTOS_CONSTANTS.SET_POSTS_IS_MORE:
      draft.isMore = action.payload;
      break;
    case POSTS_PHOTOS_CONSTANTS.SET_POSTS_PHOTOS_META:
      draft.meta = action.payload;
      break;
    case POSTS_PHOTOS_CONSTANTS.RESET_POSTS_PHOTOS:
      return initialState;
    default:
      return state;
  }
});

export default childrenPhotosReducer;
