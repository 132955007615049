import { LOADING_STATES } from 'src/utils/maps';
import { produce } from 'immer';
import { CONTEST_CONSTANTS } from 'src/modules/contest/constants/contest';
import { newState } from 'src/utils';

const initialState = {
  currentOrder: 'children',
  contestTop: {},
  perPage: 12,
  firstLoad: true,
  contestStatus: LOADING_STATES.LOADING,
  postIsLoaded: false,
  isLoaded: false,
  status: LOADING_STATES.LOADING,
  page: 0,
};

const contestReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case CONTEST_CONSTANTS.setCurrentOrder: {
      draft.currentOrder = action.payload;
      break;
    }
    case CONTEST_CONSTANTS.setContestTop: {
      draft.contestTop = action.payload;
      break;
    }
    case CONTEST_CONSTANTS.setPerPage: {
      draft.perPage = action.payload;
      break;
    }
    case CONTEST_CONSTANTS.setFirstLoad: {
      draft.firstLoad = action.payload;
      break;
    }
    case CONTEST_CONSTANTS.setContestLoadingStatus: {
      draft.contestStatus = action.payload;
      break;
    }
    case CONTEST_CONSTANTS.setPostIsLoaded: {
      draft.postIsLoaded = action.payload;
      break;
    }
    case CONTEST_CONSTANTS.setIsLoaded: {
      draft.isLoaded = action.payload;
      break;
    }
    case CONTEST_CONSTANTS.setStatus: {
      draft.status = action.payload;
      break;
    }
    case CONTEST_CONSTANTS.reset: {
      return initialState;
    }
    case CONTEST_CONSTANTS.softReset: {
      return newState(state, {
        contestTop: {},
        contestLoad: false,
        postIsLoaded: false,
        isLoaded: false,
        status: LOADING_STATES.LOADING,
        page: 0,
      });
    }
    default:
      break;
  }
});

export default contestReducer;
