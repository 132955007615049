import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { compose } from 'redux';
import Button from '@material-ui/core/Button';
import SvgIcon from '@material-ui/core/SvgIcon';
import Icon from '../../Icons';

const styles = (theme) => ({
  button: {
    fontFamily: 'Nunito Sans',
    fontWeight: 700,
    lineHeight: 1,
    textTransform: 'none',
    borderRadius: 30,
    width: 141,
    color: '#fff',
    '&.big': {
      fontSize: 18,
      height: 36,
      '&.main-page-participate': {
        width: '100%',
        maxWidth: 234,
      },
    },
    '&.small': {
      height: 30,
      fontSize: 13,
    },
    '&.participate': {
      marginLeft: 8,
      '@media (max-width: 767px)': {
        marginLeft: 0,
        marginBottom: 8,
      },
    },
    '&.vote-buttons': {
      width: 168,
      '&:disabled': {
        color: '#fff',
      },
      '@media (max-width: 767px)': {
        width: '128px',
        fontSize: 13,
        height: 31,
      },
      '@media (max-width: 480px)': {
        height: 34,
        position: 'relative',
        bottom: 2,
      },
      '@media (max-width: 360px)': {
        width: '127px',
      },
    },
    '&.stripe-button': {
      minWidth: 316,
      width: 'auto',
      padding: '0 12px',
      '&:disabled': {
        color: '#fff',
      },
      '@media (max-width: 767px)': {
        width: '100%',
        fontSize: 18,
        height: 36,
      },
      '@media (max-width: 560px)': {
        minWidth: 0,
        maxWidth: 280,
        whiteSpace: 'nowrap',
        fontSize: 13,
      },
    },
    '&.useless': {
      backgroundColor: 'var(--color-neutral-3)!important',
      '&.no-touch:hover': {
        backgroundColor: '#9b9b9b!important',
      },
      '&:disabled': {
        color: 'rgba(0, 0, 0, 0.37)!important',
        background: 'rgba(0, 0, 0, 0.11)!important',
      },
    },
    '&.mac.firefox': {
      fontWeight: 500,
    },
    '&.win': {
      fontWeight: 600,
    },
    '&.fullWidth': {
      width: '100%',
    },
    '&.imageCrop': {
      maxWidth: '110px',
    },
    '&.primary': {
      background: 'var(--color-primary)',
      '&.no-touch:hover': {
        backgroundColor: 'var(--color-primary-hover)',
      },
      '&.no-touch:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.11)',
      },
      '&:hover': {
        backgroundColor: 'var(--color-primary)',
      },
      '&.editPost': {
        backgroundColor: '#fff',
        border: '1px solid var(--color-primary)',
        color: 'var(--color-primary)',
        width: '100%',
        maxWidth: '228px',
        '&.no-touch:hover': {
          backgroundColor: '#fff',
          color: 'var(--color-primary-hover)',
        },
        '@media (max-width: 992px)': {
          width: '100%',
          maxWidth: '162px',
        },
        '@media (max-width: 767px)': {
          width: '100%',
          maxWidth: '100%',
        },
      },
    },
    '&.purchase-btn': {
      width: 226,
    },
    '&.not-answered-btn': {
      width: 'fit-content',
      padding: '0 15px',
    },
    '&.primary:disabled': {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.11)',
      },
    },
    '&.secondary': {
      background: 'var(--color-secondary)',
      '&.no-touch:hover': {
        backgroundColor: 'var(--color-secondary-2)',
      },
      '&:hover': {
        backgroundColor: 'var(--color-secondary)',
      },
    },
    '&.deleteButton.login-button': {
      color: 'rgba(255, 255, 255, 0.87)',
      padding: '6px 8px',
      marginBottom: '5px',
    },
    '&.text': {
      border: 'none',
      background: 'transparent',
      color: 'var(--color-primary)',
      '&.share': {
        backgroundColor: '#fff',
        color: 'var(--color-primary)',
        maxWidth: '100px',
        paddingLeft: '30px',
        // transform: 'translate(17px, -5px)',
        '& svg': {
          transition: 'none',
          width: '20px',
        },
        '&.no-touch:hover': {
          backgroundColor: '#fff',
          color: 'var(--color-secondary-2)',

          '& svg': {
            fill: 'var(--color-secondary-2)',
          },
        },
        '@media (max-width: 992px)': {
          // transform: 'translate(17px, -6px)',
        },
        '@media (max-width: 767px)': {
          // width: '100%',
          maxWidth: '90px',
          paddingRight: '0',
        },
        '@media (max-width: 601px)': {
          // transform: 'translate(15px, -6px)',
        },
      },
      '&.no-touch:hover': {
        backgroundColor: 'var(--color-background-hover)',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.small.edge': {
        fontWeight: 700,
      },
      '&.small.win.chrome': {
        fontWeight: 700,
      },
      '&.header': {
        color: 'var(--color-text)',
        width: 'auto',
        padding: '0 12px',
        fontSize: 16,
        position: 'relative',
        top: 1,
        fontWeight: 700,
        left: -1,
        '&.no-touch:hover': {
          background: 'var(--color-background-hover)',
        },
        '&:hover': {
          background: 'transparent',
        },
        '&.win': {
          fontWeight: 700,
        },
        '&.mac.firefox': {
          fontWeight: 600,
        },
        '@media (max-width: 1240px)': {
          fontSize: 13,
          padding: '0 13px',
        },
        '&.scrolled': {
          fontSize: 13,
          padding: '0 9px',
        },
        '&.small': {
          fontSize: 13,
        },
        '&.noLeft': {
          left: 0,
        },
      },
    },
    '&.deleteSubmit.text': {
      fontSize: '13px',
      fontWeight: '700',
      letterSpacing: '0.06px',
      color: 'var(--color-primary)',
      padding: '6px 8px',
      '&.mac.firefox': {
        fontWeight: 600,
      },
      '&:hover': {
        color: 'var(--color-primary)',
      },
    },
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.37)',
      background: 'rgba(0, 0, 0, 0.11)',
      '&.mac.firefox': {
        fontWeight: 600,
      },
      '&.win.chrome': {
        fontWeight: 600,
      },
      '&.win.firefox': {
        fontWeight: 700,
      },
      '&.win.edge': {
        fontWeight: 700,
      },
      '&.outlined': {
        background: 'transparent',
        border: '1px solid rgba(0, 0, 0, 0.11)',
        color: 'rgba(0, 0, 0, 0.37)',
      },
    },
    '&.outlined': {
      background: 'transparent',
      border: '1px solid var(--color-primary)',
      color: 'var(--color-primary)',
      paddingTop: '8px',
      '&.no-touch:hover': {
        background: 'var(--color-background-hover)',
      },
      '&:hover': {
        background: 'transparent',
      },
      '&.win': {
        fontWeight: 700,
      },
      '&.auto': {
        minWidth: 141,
        width: 'auto',
      },
      '&.regionItem': {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        width: 'auto',
        height: '32px',
        color: 'var(--color-text)',
        padding: '0 12px',
        'font-size': '15px',
        'font-weight': '400',

        '&.withIcon': {
          'padding-left': '4px',
        },

        '&.active': {
          background: 'var(--color-primary)',
          color: '#ffffff',
        },

        '&.no-touch:hover': {
          border: '1px solid var(--color-primary)',
        },
      },
    },
    '&.withIcon': {
      position: 'relative',
      paddingLeft: '21px',
      '& svg': {
        padding: 0,
        position: 'absolute',
        left: 10,
      },
    },
    '&.header_action': {
      width: 155,
      padding: 0,
      flexShrink: 0,
      // eslint-disable-next-line no-dupe-keys
      '&.scrolled_button': {
        width: '155px!important',
      },
      '@media (max-width: 1366px)': {
        width: 24,
        maxWidth: 24,
        minWidth: 24,
        height: 24,
      },
      '& > span > span': {
        '@media (max-width: 1366px)': {
          display: 'none',
        },
      },
      '& svg': {
        display: 'none',
        '@media (max-width: 1366px)': {
          display: 'block',
          width: 20,
        },
      },
      '&.win': {
        fontWeight: '500',
      },
      // eslint-disable-next-line no-dupe-keys
      '&.scrolled_button': {
        '@media (max-width: 1919px)': {
          width: '129px !important',
          '&.fi': {
            width: '164px!important',
          },
          '&.fr': {
            width: '164px!important',
          },
        },
        '@media (min-width: 1919px)': {
          width: '129px !important',
          '&.fi': {
            width: '164px!important',
          },
          '&.fr': {
            width: '164px!important',
          },
        },
        '@media (max-width: 1240px)': {
          display: 'none',
        },
      },
      '&.scrolled_button.add_child': {
        '@media (max-width: 1240px)': {
          display: 'flex',
        },
      },
    },
    '&.header_action_login.header_action.scrolled_button': {
      width: '148px !important',
      '&.scrolled_button': {
        '@media (max-width: 1919px)': {
          width: '148px !important',
          '&.fi': {
            width: '164px !important',
          },
          '&.fr': {
            width: '164px !important',
          },
        },
      },
    },
    '&.header_action_login.header_action': {
      width: 148,
      maxWidth: 148,
      '@media (max-width: 1240px)': {
        maxWidth: 'initial',
        marginLeft: 21,
      },
      '&.fi': {
        width: 164,
        maxWidth: 164,
      },
      '&.fr': {
        width: 170,
        maxWidth: 170,
      },
      '&.scrolled_button': {
        '@media (max-width: 1919px)': {
          width: '120px !important',
        },
      },
    },
    '&.leaderboard-btn': {
      '@media(max-width: 767px)': {
        width: '100%',
        height: '36px',
        fontSize: '18px',
      },
    },
    '&.voteForPost': {
      color: 'rgba(255, 255, 255, 0.87)',
      // maxWidth: '226px',
      maxWidth: '100%',
      '@media (max-width: 992px)': {
        // maxWidth: '162px',
      },
      '@media (max-width: 767px)': {
        width: '100%',
        // maxWidth: '100%',
      },
    },
    '&.scrolled_button': {
      width: '76px !important',
      '&.win': {
        fontWeight: '500',
      },
      color: 'var(--color-text)',
      '&.primary': {
        color: '#fff',
      },
      '&.secondary': {
        color: '#fff',
      },
      '&.header_action': {
        width: '120px!important',
      },
      '@media (max-width: 1920px)': {
        width: '65px !important',
      },
    },
    '&.sort_button': {
      width: 154,
      '&.text': {
        color: 'rgba(0, 0, 0, 0.6)',
        '&.no-touch:hover': {
          background: 'transparent',
          color: 'var(--color-primary)',
        },
        '&:hover': {
          background: 'transparent',
        },
        '&.win': {
          fontWeight: 600,
        },
        '&.win.text': {
          fontWeight: 700,
        },
        '&.mac.firefox': {
          fontWeight: 600,
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: 138,
      },
      '&.contest-sort': {
        '@media(max-width: 767px)': {
          fontSize: 14,
          width: 70,
          height: 32,
          whiteSpace: 'nowrap',
        },
      },
      '&:disabled': {
        backgroundColor: 'none!important',
      },
    },
    '&.search_button': {
      width: 'auto',
      padding: '6px 31px',
    },
    '&.breadcrumbs': {
      '&.outlined': {
        background: 'transparent',
        border: '1px solid rgba(0, 0, 0, 0.11)',
        color: 'rgba(0, 0, 0, 0.37)',
      },
      width: 'auto',
      height: 24,
      padding: '6px 14.2px',
      color: 'rgba(0, 0, 0, 0.6)',
      background: 'rgba(0, 0, 0, 0.07)',
      '&.mac': {
        fontWeight: '500 !important',
      },
      '&.win.chrome': {
        fontWeight: '600 !important',
      },
      '&.win.firefox': {
        fontWeight: '500 !important',
      },
      '&.win.edge': {
        fontWeight: '600 !important',
      },
      '&:disabled': {
        opacity: '.52',
      },
    },
    '& .ellipsis': {
      textOverflow: 'ellipsis',
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '&.account_change': {
      width: 'auto',
      padding: '6px 10px',
      marginTop: '8px',
    },
    '&.google': {
      width: '100%',
      position: 'relative',
      paddingRight: '10px!important',
      border: '1px solid #D7D7D7',
      '&.login-button': {
        color: 'rgba(0, 0, 0, 0.6)',
        '&.no-touch:hover': {
          color: 'rgba(0, 0, 0, 0.6)',
          opacity: 0.85,
        },
      },
      '& svg': {
        position: 'absolute',
        left: 6,
        width: 20,
      },
      '&.no-touch:hover': {
        // background: 'rgba(65,134,245, 0.85)',
        opacity: 0.85,
      },
    },
    '&.tiktock': {
      width: '100%',
      background: 'black',
      position: 'relative',
      paddingRight: '10px!important',
      '& svg': {
        position: 'absolute',
        left: 6,
        width: 20,
      },
      '&.no-touch:hover': {
        background: 'rgba(0,0,0, 0.85)',
      },
    },
    '&.facebook': {
      width: '100%',
      background: '#3b579d',
      position: 'relative',
      paddingRight: '10px!important',
      '& svg': {
        fill: '#fff',
        position: 'absolute',
        left: 6,
        width: 20,
      },
      '& path': {
        fill: '#fff',
      },
      '&.no-touch:hover': {
        background: 'var(--color-neutral-5)',
      },
    },
    '&.login-button': {
      width: '100%',
      marginBottom: '16px',
      paddingRight: 21,
      color: 'rgba(255, 255, 255, 0.87)',
      '& svg': {
        fill: '#fff',
        left: '7px!important',
        width: 20,
      },
      '&.no-touch:hover': {
        color: 'rgba(255, 255, 255, 1)',
      },
    },
    '&.deleteAccount': {
      width: 'auto',
      color: 'rgba(0, 0, 0, 0.6)',
      paddingRight: '20px',
      paddingLeft: '40px',
      paddingTop: '8px',
      marginTop: '3px',
      fontWeight: '600',
      '& svg': {
        top: '2px',
        left: '7px!important',
        fill: 'rgba(0, 0, 0, 0.32)',
      },
      '@media (max-width: 701px)': {
        width: 153,
        margin: '0 auto',
      },
      '&.win': {
        fontWeight: '500 !important',
      },
      '&.win.edge': {
        fontWeight: '600 !important',
      },
      '&.mac.safari': {
        fontWeight: 300,
      },
      '&.mac.chrome': {
        fontWeight: '500 !important',
      },
    },
    '&.send-button-support': {
      '@media(max-width: 767px)': {
        width: '100%',
        maxWidth: '100%',
      },
    },
    '&.deleteAccount.deletePost': {
      '@media (max-width: 701px)': {
        paddingLeft: 45,
        width: 153,
        margin: '0 auto',
        '& svg': {
          left: '21px !important',
        },
      },
    },
  },
});

const CustomButton = ({
  classes,
  className,
  children,
  disabled,
  iconName,
  iconType,
  browser,
  device,
  social,
  onClick,
  submit,
  lang,
  customIcon,
  ...rest
}) => (
  <Button
    disableRipple={!device.includes('no-touch') || className.includes('share')}
    variant="text"
    color="inherit"
    type={submit ? 'submit' : 'button'}
    className={classNames(
      classes.button,
      className,
      browser,
      device,
      lang,
      iconName ? 'withIcon' : '',
    )}
    disabled={disabled}
    onClick={onClick}
    {...rest}
  >
    {social ? (
      <SvgIcon>
        {' '}
        <path
          fill="#000000"
          d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z"
        />
      </SvgIcon>
    ) : (
      ''
    )}
    {iconName ? <Icon name={iconName} type={iconType} /> : ''}
    {customIcon && customIcon}
    {children}
  </Button>
);

CustomButton.propTypes = {
  browser: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  device: PropTypes.string,
  disabled: PropTypes.bool,
  iconName: PropTypes.string,
  iconType: PropTypes.string,
  iphoneX: PropTypes.string,
  onClick: PropTypes.func,
  social: PropTypes.object,
};

CustomButton.defaultProps = {
  children: '',
  className: '',
  disabled: false,
  iconName: '',
  iconType: '',
  link: '',
};

function mapStateToProps(state) {
  const {
    browser, device, iphoneX, currentCountry,
  } = state.app;
  return {
    browser,
    device,
    iphoneX,
    lang:
      currentCountry
      && currentCountry.language
      && currentCountry.language.toLowerCase(),
  };
}

export default compose(
  connect(mapStateToProps, null),
  withStyles(styles),
)(memo(CustomButton));
