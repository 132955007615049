import { USER_CONSTANTS } from 'src/constants/user';
import {
  SET_USER, LOGOUT, READ_NOTIFICATION,
} from '../actionTypes';
import { logoutApi } from '../modules/common/api';

export const setUser = (userParams) => ({
  type: SET_USER,
  payload: userParams,
});

export const logoutAction = () => {
  localStorage.removeItem('timeToFreeVote');
  return { type: LOGOUT };
};

export const readUserNotification = () => ({ type: READ_NOTIFICATION });

export const logout = (apiToken) => {
  localStorage.removeItem('timeToFreeVote');
  localStorage.removeItem('littleswipeWinItem');

  return (dispatch) => {
    dispatch(logoutAction());
    logoutApi(apiToken).then((data) => {
      dispatch(logoutAction());
    });
  };
};

export const setWallet = (payload) => ({
  type: USER_CONSTANTS.SET_WALLET,
  payload,
});

export const updateUser = (payload) => ({
  type: USER_CONSTANTS.UPDATE,
  payload,
});
