import { proxyHandler } from 'src/utils/redux';

const prefix = '@leaderboard';

const constants = {
  setCurrentOrder: 'setCurrentOrder',
  setContest: 'setContest',
  setContestPosts: 'setContestPosts',
  setNextLink: 'setNextLink',
  setWinners: 'setWinners',
  setShowMoreLoading: 'setShowMoreLoading',
  setFirstLoad: 'setFirstLoad',
  setWinnersLoading: 'setWinnersLoading',
  setSearchID: 'setSearchID',
  setWinnersNextLink: 'setWinnersNextLink',
  setStatus: 'setStatus',
  reset: 'reset',
  setPage: 'setPage',
  softReset: 'softReset',
};

export const LEADERBOARD_CONSTANTS = new Proxy(constants, proxyHandler(prefix));
