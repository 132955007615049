import {
  FETCH_POSTS_SUCCESS,
  RESETE_POSTS,
  FETCH_POSTS_START,
  NEXT_PAGE_SUCCESS,
  SWITCH_PAGE,
  SHOW_NEXT_PAGE,
  SHOW_POSTS,
  SET_FIRST_TIME,
  RESETE_STATE,
  NEXT_PAGE_POSTS_LOADED,
} from '../actionTypes';
import { splitArrayToChunk, newState } from '../utils';

const initialState = {
  page: 1,
  order: 'recent',
  perPage: null,
  posts: [Array.from({
    length: 20,
  }, () => ({
    image: '',
    countVotes: '',
    name: '',
    userNickname: '',
    isNew: false,
  }))],
  isLoading: false,
  nextPageLoaded: false,
  column: 1,
  oldPosts: [],
  firstTime: true,
  offset: 0,
  disabledPage: false,
};

const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POSTS_SUCCESS:
      let { column } = action.payload;
      if (!column) {
        column = state.column;
      }
      let oldPosts = [...state.posts];
      if (action.payload.order !== state.order) {
        oldPosts = [];
      }
      let arrWithId = [];
      oldPosts.forEach((page) => {
        if (page) {
          const pageArrId = page.map((element) => element.id);
          arrWithId = [...arrWithId, ...pageArrId];
        }
      });
      let newPostsCopy = [...action.payload.posts];
      newPostsCopy = newPostsCopy.filter((element) => {
        if (arrWithId.includes(element.id)) {
          return false;
        }
        return true;
      });
      if (action.payload.page > 1) {
        let block = '';
        try {
          const postBlock = oldPosts[action.payload.page - 2][
            oldPosts[action.payload.page - 2].length - 1
          ];
          if (postBlock && postBlock.randomBlock === 'list') {
            block = 'cta';
          } else if (postBlock && postBlock.randomBlock === 'cta') {
            block = 'winners';
          } else if (postBlock && postBlock.randomBlock === 'weekly') {
            block = 'weekly';
          } else {
            block = 'list';
          }
        } catch (e) {
          block = 'list';
        }
        if (newPostsCopy[newPostsCopy.length - 1]) {
          newPostsCopy[newPostsCopy.length - 1].randomBlock = block;
        }
      }
      oldPosts[action.payload.page - 1] = newPostsCopy;
      const { page } = action.payload;

      const processedArrayNextPage = oldPosts.flat().map((item, index) => {
        if (index % 32 === 0 && index > 0) {
          return {
            ...item,
            randomBlock: 'weekly',
          };
        }

        if (index % 8 && action.payload.page === 1) {
          return {
            ...item,
            randomBLock: 'dogster',
          };
        }

        return item;
      });

      const chankedArrayNextPage = splitArrayToChunk(
        processedArrayNextPage,
        action.payload.perPage,
      );
      return newState(state, {
        posts: chankedArrayNextPage,
        isLoading: false,
        page,
        order: action.payload.order,
        perPage: action.payload.perPage,
        column,
        offset: action.payload.offset,
        disabledPage: action.payload.posts.length === 0,
      });
    case RESETE_POSTS:
      return newState(state, {
        oldPosts: state.posts,
        posts: [[]],
        page: 1,
        isLoading: true,
      });
    case RESETE_STATE:
      return initialState;
    case SHOW_POSTS:
      return newState(state, {
        ...state,
        posts: state.oldPosts,
        page: 1,
        isLoading: false,
      });
    case FETCH_POSTS_START:
      return {
        ...state,
        isLoading: true,
        nextPageLoaded: false,
      };
    case NEXT_PAGE_SUCCESS:
      const olDPosts = [...state.posts];
      const newPosts = splitArrayToChunk(action.payload.posts, state.perPage);
      newPosts.forEach((element, index) => {
        let block = '';
        if (index === 0) {
          block = 'cta';
        } else if (index === 1) {
          block = 'winners';
        } else {
          block = 'list';
        }
        let arrWithId = [];
        olDPosts.forEach((pageNumber) => {
          if (pageNumber) {
            const pageArrId = pageNumber.map((element1) => element1.id);
            arrWithId = [...arrWithId, ...pageArrId];
          }
        });

        let newPostsCopy = [...element];
        newPostsCopy = newPostsCopy.filter((element2) => {
          if (arrWithId.includes(element2.id)) {
            return false;
          }
          return true;
        });
        if (newPostsCopy[newPostsCopy.length - 1]) {
          newPostsCopy[newPostsCopy.length - 1].randomBlock = block;
        }
        olDPosts[index + 1] = newPostsCopy;
      });

      const processedArray = olDPosts.flat().map((item, index) => {
        if (index % 32 === 0 && index > 0) {
          return {
            ...item,
            randomBlock: 'weekly',
          };
        }

        return item;
      });
      const chankedArray = splitArrayToChunk(processedArray, state.perPage);

      return newState(state, {
        posts: chankedArray,
        isLoading: false,
        offset: action.payload.offset,
      });
    case NEXT_PAGE_POSTS_LOADED:
      return newState(state, {
        nextPageLoaded: action.payload,
      });
    case SWITCH_PAGE:
      return newState(state, {
        posts: [...state.posts, ...state.nextPage],
        isLoading: false,
      });
    case SHOW_NEXT_PAGE:
      return newState(state, {
        page: state.page + 1,
      });
    case SET_FIRST_TIME:
      return newState(state, {
        firstTime: false,
      });
    default:
      return state;
  }
};

export default postsReducer;
