import { USER_CONSTANTS } from 'src/constants/user';
import { LOGOUT, READ_NOTIFICATION, SET_USER } from '../actionTypes';
import { newState } from '../utils';

const initialState = {
  login: false,
  apiToken: '',
  id: 1,
  isAnyPosts: 0,
  nickname: '',
  image: '',
  location: '',
  countryCode: '',
  email: '',
  phone: '',
  city: '',
  facebookID: null,
  socials: {
    facebook: { id: null, image: null, name: null },
    google: { id: null, image: null, name: null },
    tiktok: { id: null, image: null, name: null },
  },
  timeToFreeVote: null,
  accountKitId: null,
  unsubscribe: false,
  emailNotificationSettings: {},
  wallet: {
    amount: 0,
    currency_code: 'USD',
    formatted_amount: '$0.00',
    withdraw: {
      available_to_withdraw: '$0.00',
      total_winnings: '$0.00',
      total_winnings_votes: 0,
    },
  },
  referral: {
    nickname: '',
    image: null,
    code: null,
    count_registrations: 0,
    countVotes: 0,
    is_blocked: false,
    is_limit_reached: false,
  },
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      const data = newState(state, { ...action.payload, login: true });
      localStorage.setItem('userData', JSON.stringify(data));
      return data;
    case LOGOUT:
      localStorage.removeItem('userData');
      return initialState;
    case READ_NOTIFICATION:
      return newState(state, {
        ...action.payload,
        hasUnreadNotifications: false,
      });
    case USER_CONSTANTS.SET_WALLET:
      return newState(state, {
        ...state,
        balance: action.payload,
      });
    case USER_CONSTANTS.UPDATE:
      return newState(state, {
        ...state,
        ...action.payload,
      });
    default:
      return state;
  }
};

export default userReducer;
