import { SET_UNAUTHORIZED_COMMENT } from '../actionTypes';
import { newState } from '../utils';

const initialState = {
  unAuthorizedComment: '',
  unAuthorizedCommentParent: null,
};

const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNAUTHORIZED_COMMENT:
      return newState(state, {
        unAuthorizedComment: action.payload.comment,
        unAuthorizedCommentParent: action.payload.parentId,
      });

    default:
      return state;
  }
};

export default postsReducer;
