import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import React, { PureComponent } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, defineMessages } from 'react-intl';
import 'intersection-observer';
import TagManager from 'react-gtm-module';
import SocketLoader from 'components/common/SocketLoader';
import { initialScript, checkAdBlocker } from './utils';
import ErrorBoundary from './components/common/ErrorBoundary';
import {
  setBrowser,
  setDevice,
  setIphoneX,
  toggleModalLogin,
  triggerSnackBar,
  toggleRestore,
  triggerPreloadPopup,
  setAdblock,
  setProduction,
  triggerMessengerPopup,
  setCurrentCountry,
  setUnlockedAchievementsData,
  setUnlockedAchievementsOpened,
  addUnlockedAchievementsData,
  setCountryModalDisabled, setTournamentsFeatureModal,
} from './actions/app';
import { setReceiveAchievement } from './actions/achievements';
import { setUser, logout, updateUser } from './actions/user';
import './styles/theme.scss';
import './components/typography/text.module.scss';
import Preloader from './components/Preloader';
import { getUserData } from './modules/common/api';
import style from './App.module.scss';
import { DisabledCountryModal } from 'src/modules/common/components/DisabledCountry';
import TournamentsFeatureModal from 'src/modules/tournaments/components/TournamentsFeatureModal';
import { patchSavePopupPreview } from 'src/modules/tournaments/api/tournament';
// import TournamentNotifications from 'src/modules/tournaments/components/TournamentNotifications';
import ScrollToTop from './utils/scrollToTop';
// import MetaTags from 'react-meta-tags';

const Login = loadable(() => import('./components/RegistrationLogin/Login'));
const Header = loadable(() => import('./components/Header'));
const Popup = loadable(() => import('./components/Popup'));
const Footer = loadable(() => import('./components/Footer'));
const Snackbar = loadable(() => import('@material-ui/core/Snackbar'));
const RouteWrapper = loadable(() => import('./RouteWrapper.jsx'));
const MessengerPopup = loadable(() => import('./components/SocialComponents/MessengerPopup/MessengerPopup.jsx'));
const Restore = loadable(() => import('./components/RegistrationLogin/Restore'));
const NewAchievementsModal = loadable(() => import('./components/AchievementComponents/AchievementModal/NewAchievementsModal.jsx'));

const messages = defineMessages({
  try_again: {
    id: 'try_again',
    defaultMessage: 'Something has happened, please try signing in again',
  },
});

class App extends PureComponent {
  static propTypes = {
    browser: PropTypes.string,
    device: PropTypes.string,
    iphoneX: PropTypes.string,
    login: PropTypes.bool,
    modalLoginIsOpen: PropTypes.bool,
    preloadPopup: PropTypes.bool,
    restore: PropTypes.bool,
    setBrowser: PropTypes.func.isRequired,
    setDevice: PropTypes.func.isRequired,
    setIphoneX: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    snackBar: PropTypes.bool,
    snackBarMessage: PropTypes.string,
    toggleModalLogin: PropTypes.func,
    triggerPreloadPopup: PropTypes.func.isRequired,
    triggerSnackBar: PropTypes.func.isRequired,
    triggerMessengerPopup: PropTypes.func.isRequired,
  };

  state = {
    achievementStage: 'default',
  };

  warningBannerContainer = null;

  socket = null;

  async componentWillMount() {
    const userData = localStorage.getItem('userData');
    if (userData) {
      this.props.setUser(JSON.parse(userData));
      const token = JSON.parse(userData).apiToken;
      const userResponse = await getUserData(token);
      const user = await userResponse.json();
      this.props.setUser(user);
      initialScript('https://cdn.onesignal.com/sdks/OneSignalSDK.js', () => {
        window.OneSignal.push(() => {
          window.OneSignal.SERVICE_WORKER_PARAM = { scope: '/js/' };
          window.OneSignal.SERVICE_WORKER_PATH = 'js/OneSignalSDKWorker.js';
          window.OneSignal.SERVICE_WORKER_UPDATER_PATH = 'js/OneSignalSDKUpdaterWorker.js';
          window.OneSignal.init({
            appId:
              process.env.REACT_APP_PRODUCTION === 'TRUE'
                ? '4c7ed094-7cf3-43db-89b1-bd1d4fc450bf'
                : '4c7ed094-7cf3-43db-89b1-bd1d4fc450bf',
            notifyButton: {
              enable: false,
            },
            autoResubscribe: true,
          });
          window.OneSignal.showNativePrompt();
          window.OneSignal.on('subscriptionChange', (isSubscribed) => {
            console.log("The user's subscription state is now:", isSubscribed);
          });
        });
      });
    }
  }

  currentUser = null;

  async componentDidMount() {
    const {
      intl: { formatMessage },
    } = this.props;

    window.addEventListener('focus', () => {
      const { currentCountry, setCurrentCountry } = this.props;

      let currentCountryLocalStorage = null;
      try {
        currentCountryLocalStorage = JSON.parse(
          localStorage.getItem('currentCountry'),
        );
      } catch (e) {
        console.log(e);
        localStorage.removeItem('currentCountry');
      }

      if (
        currentCountryLocalStorage
        && currentCountryLocalStorage.id
        && currentCountry
        && currentCountry.id
        && currentCountryLocalStorage.id !== currentCountry.id
      ) {
        setCurrentCountry(currentCountryLocalStorage);
      }
    });

    document.addEventListener('visibilitychange', () => {
      const userData = localStorage.getItem('userData');
      // console.log("visibilitychange",userData)
      if (this.currentUser && !userData) {
        this.props.triggerSnackBar(true, formatMessage(messages.try_again));
        this.props.logout();
      }
      this.currentUser = userData;
    });
    this.checkDevice();
    this.checkBrowser();
    this.checkIphoneX();
    if (process.env.REACT_APP_PRODUCTION === 'TRUE') {
      const usingAblocked = await checkAdBlocker();
      if (usingAblocked) {
        this.props.setAdblock();
      }

      this.props.setProduction();
      // const tagManagerArgs = {
      //
      //   // gtmId: 'GTM-T8VCJDH',
      //   gtmId: 'GTM-PRDSPQS',
      //   auth: 'quUZtZyCJuf508951FiqMA',
      //   preview: 'env-2',
      // };
      // TagManager.initialize(tagManagerArgs);
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   setTimeout(() => {
  //     if (this.props.login && !prevProps.login && !this.props.is_showed_tournament_popup) {
  //       // this.props.setTournamentsFeatureModal(true);
  //     }
  //   }, 3000);
  // }

  achievementHandler = (data) => {
    // не показывать модальное окно нотификаций если вкладка не в фокусе.
    // Если ачивка с соц. сетями то показывать в любом случае,
    // чтобы после редиректа увидеть полученную ачивку
    if (
      document.hidden
      && data.code !== 'true_follower'
      && data.code !== 'true_fan'
    ) {
      return;
    }

    // console.log(data);

    this.props.setReceiveAchievement(data.id);
    this.props.addUnlockedAchievementsData(data);

    // таймаут для обработки получения нескольких ачивок сразу
    setTimeout(() => {
      if (!this.props.unlockedAchievements.opened) {
        const noAchievementsElement = document.querySelector('.no-achievements');
        if (noAchievementsElement) {
          noAchievementsElement.setAttribute(
            'style',
            'display: none!important;',
          );
          noAchievementsElement
            .closest('div[role="dialog"]')
            .setAttribute('style', 'display: none!important;');
        }
        this.props.setUnlockedAchievementsOpened(true);
      }
    }, 1500);
  };

  checkDevice() {
    const iOSIphone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
    const isMac = !!navigator.platform && /Mac/.test(navigator.platform);
    const iOSPad = /iPad/.test(navigator.userAgent) && !window.MSStream;
    const isWindows = /Win/.test(navigator.platform);
    const isAndroid = /Android/.test(navigator.userAgent);
    const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
    let device = '';
    if (iOSIphone) {
      device += 'iphone';
      document.querySelector('html').classList.add('iphone');
    }

    if (iOSPad) {
      device += ' ipad';
    }

    if (iOSIphone || iOSPad) {
      device += ' ios';
    }

    if (isAndroid) {
      device += ' android';
    }

    if (isMac) {
      device += ' mac';
    }

    if (isWindows) {
      device += ' win';
    }
    if (isAndroid || iOSIphone || iOSPad) {
      document.querySelector('html').classList.add('device');
    }
    if (!supportsTouch) {
      document.querySelector('html').classList.add('no-touch');
      device += ' no-touch';
    }
    this.props.setDevice(device);
  }

  checkBrowser() {
    const isOpera = !!window.opr
      || !!window.opera
      || navigator.userAgent.indexOf(' OPR/') >= 0;
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const isSafari = /Constructor/.test(window.HTMLElement)
      || ((root) => (!root || root.pushNotification).toString()
        === '[object SafariRemoteNotification]')(window.safari);
    const isIE = /* @cc_on!@ */ false || !!document.documentMode;
    const isEdge = !isIE && !!window.StyleMedia;
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    let browser = '';
    if (isOpera) {
      browser += 'opera';
    }

    if (isFirefox) {
      browser += ' firefox';
    }
    const isSafarii = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (isSafarii && iOS) {
      browser += ' safari';
    } else if (isSafarii) {
      browser += ' safari';
    }

    if (isIE) {
      browser += ' ie';
    }

    if (isEdge) {
      browser += ' edge';
    }

    if (isChrome) {
      browser += ' chrome';
    }

    const { setBrowser } = this.props;
    setBrowser(browser);
  }

  checkIphoneX() {
    const ios = /iPhone/.test(navigator.userAgent) && !window.MSStream;
    const pixelRatio = window.devicePixelRatio;
    const deviceWidth = window.screen.width;
    const deviceHeight = window.screen.height;
    if (
      ios
      && /2|3/.test(pixelRatio)
      && /812|896/.test(deviceHeight)
      && /375|414/.test(deviceWidth)
    ) {
      document.querySelector('html').classList.add('iphoneX');
      const { setIphoneX } = this.props;
      setIphoneX('iphoneX');
    }
  }

  modalLoginOnCloseHandle = () => this.props.toggleModalLogin(false, '');

  handleNewAchievementsClose = () => {
    this.props.setUnlockedAchievementsOpened(false);
    this.props.setUnlockedAchievementsData([]);
    this.setAchievementStage('default');

    const noAchievementsElement = document.querySelector('.no-achievements');

    if (noAchievementsElement) {
      noAchievementsElement.removeAttribute('style');
      noAchievementsElement
        .closest('div[role="dialog"]')
        .removeAttribute('style');
      setTimeout(() => {
        document
          .querySelector('body')
          .setAttribute('style', 'overflow: hidden;');
      }, 100);
    }
  };

  setAchievementStage = (stage) => {
    this.setState({
      achievementStage: stage,
    });
  };

  render() {
    const {
      browser,
      device,
      iphoneX,
      modalLoginIsOpen,
      login,
      snackBar,
      snackBarMessage,
      restore,
      preloadPopup,
      showMessengerPopup,
      id,
      triggerMessengerPopup,
      unlockedAchievements,
      countryModalDisabled,
      tournamentsFeatureModal,
      is_showed_referral_congratulations_popup,
    } = this.props;
    // console.log("unlockedAchievements", unlockedAchievements)
    // console.log('is_showed_referral_congratulations_popup', is_showed_referral_congratulations_popup);
    if (!this.warningBannerContainer) this.warningBannerContainer = document.getElementById('warningBanner');
    return (
      <React.Fragment>
        <ErrorBoundary>
          <div className={`App ${device} ${browser} ${iphoneX}`}>
            <Router preserverScrollPosition={false}>
              <ScrollToTop />
              <Header
                authorized={login}
                sortBy="recent"
                changeSorting={this.changeSorting}
                warningBannerContainer={this.warningBannerContainer}
              />
              <div
                style={{
                  minHeight: 'calc(100vh - 300px)',
                }}
              >
                <RouteWrapper />
              </div>
              <Footer />

              {unlockedAchievements.opened && !is_showed_referral_congratulations_popup ? (
                <Popup
                  open={unlockedAchievements.opened}
                  closeOnEsc
                  closeOnOverlay
                  closeBtn
                  wrapperClassname="modal-wrapper"
                  onClose={this.handleNewAchievementsClose}
                  achievements={
                    unlockedAchievements.achievements.length > 1
                    && this.state.achievementStage === 'default'
                  }
                >
                  <NewAchievementsModal
                    achievementStage={this.state.achievementStage}
                    setAchievementStage={this.setAchievementStage}
                  />
                </Popup>
              ) : null}

              {modalLoginIsOpen ? (
                <Popup
                  open={modalLoginIsOpen}
                  closeOnEsc
                  onClose={this.modalLoginOnCloseHandle}
                  closeOnOverlay
                  closeBtn
                  wrapperClassname="modal-wrapper"
                >
                  <Login />
                </Popup>
              ) : null}

              {showMessengerPopup ? (
                <Popup
                  open={showMessengerPopup}
                  closeOnEsc
                  closeOnOverlay
                  closeBtn
                  onClose={() => {
                    triggerMessengerPopup(false);
                    localStorage.setItem('prevConditional', new Date());
                  }}
                  wrapperClassname="modal-wrapper"
                >
                  <MessengerPopup
                    userId={id}
                    closePopup={() => triggerMessengerPopup(false)}
                  />
                </Popup>
              ) : null}

              {snackBar ? (
                <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  key="bottom,center"
                  className={style.bottomSnackbar}
                  autoHideDuration={5000}
                  open={snackBar}
                  onClose={() => {
                    this.props.triggerSnackBar(false);
                  }}
                  ContentProps={{
                    'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">{snackBarMessage}</span>}
                />
              ) : null}

              {restore ? (
                <Popup
                  open={restore}
                  position="centerCenter"
                  closeOnEsc
                  onClose={() => {
                    this.setState({ deleteStep1: false });
                  }}
                  wrapperClassname="modal-wrapper"
                >
                  <Restore />
                </Popup>
              ) : null}

              {preloadPopup ? (
                <Popup
                  open={preloadPopup}
                  closeOnEsc={false}
                  disableBody
                  onClose={() => this.props.triggerPreloadPopup(false)}
                  closeOnOverlay={false}
                  closeBtn={false}
                  preloader
                  wrapperClassname="modal-wrapper"
                >
                  <div className="square">
                    <Preloader />
                  </div>
                </Popup>
              ) : null}
              {countryModalDisabled ? (
                <Popup
                  open={countryModalDisabled}
                  closeOnEsc
                  disableBody
                  onClose={() => this.props.setCountryModalDisabled(false)}
                  closeOnOverlay
                  closeBtn
                  preloader
                  wrapperClassname="modal-wrapper"
                >
                  <DisabledCountryModal />
                </Popup>
              ) : null}
              {login && tournamentsFeatureModal && (
                <Popup
                  open={tournamentsFeatureModal}
                  closeOnEsc
                  disableBody
                  tournamentsFeature
                  closeBtn
                  onClose={() => {
                    this.props.updateUser({
                      is_showed_tournament_popup: true,
                    });
                    patchSavePopupPreview();
                    this.props.setTournamentsFeatureModal(false);
                  }}
                >
                  <TournamentsFeatureModal />
                </Popup>
              )}
              <SocketLoader achievementHandler={this.achievementHandler} />
              {/* <TournamentNotifications /> */}
            </Router>
          </div>
        </ErrorBoundary>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {
    browser,
    device,
    iphoneX,
    modalLoginIsOpen,
    snackBar,
    snackBarMessage,
    restore,
    preloadPopup,
    showMessengerPopup,
    currentCountry,
    achievementsModal,
    votePopup,
    unlockedAchievements,
    countryModalDisabled,
    tournamentsFeatureModal,
    is_showed_referral_congratulations_popup,
  } = state.app;
  const {
    login, apiToken, id, facebookID, phone, email, is_showed_tournament_popup,
  } = state.user;

  return {
    browser,
    device,
    iphoneX,
    modalLoginIsOpen,
    login,
    apiToken,
    snackBar,
    snackBarMessage,
    restore,
    preloadPopup,
    showMessengerPopup,
    id,
    facebookID,
    phone,
    email,
    currentCountry,
    achievementsModal,
    votePopup,
    unlockedAchievements,
    countryModalDisabled,
    tournamentsFeatureModal,
    is_showed_tournament_popup,
    is_showed_referral_congratulations_popup,
  };
}

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    setBrowser,
    setDevice,
    setIphoneX,
    toggleModalLogin,
    setUser,
    logout,
    triggerSnackBar,
    toggleRestore,
    triggerPreloadPopup,
    setAdblock,
    setProduction,
    triggerMessengerPopup,
    setCurrentCountry,
    setReceiveAchievement,
    setUnlockedAchievementsData,
    setUnlockedAchievementsOpened,
    addUnlockedAchievementsData,
    setCountryModalDisabled,
    updateUser,
    setTournamentsFeatureModal,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(App));
