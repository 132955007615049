import {
  SET_SEARCH_TYPE,
  SET_SEARCH_QUERY,
  FETCH_SEARCH_SUCCESS,
  NEXT_SEARCH,
  NEXT_PAGE_START,
  RESET_SEARCH,
  CHANGE_INPUT_VALUE,
  FETCH_SEARCH_START,
} from '../actionTypes';
import { searchFactory, newState } from '../utils';

const initialState = {
  query: '',
  type: 'users',
  posts: [],
  users: [],
  postsNextLink: '',
  usersNextLink: '',
  isLoading: false,
  inputValue: '',
  emptyResultPosts: false,
  emptyResultUsers: false,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_TYPE:
      return newState(state, { type: action.payload });
    case SET_SEARCH_QUERY:
      return newState(state, { query: action.payload });
    case FETCH_SEARCH_SUCCESS:
      const newResult = searchFactory(
        action.payload.data,
        action.payload.requestName,
      );
      let nextLink = 'usersNextLink';
      if (newResult.type === 'posts') {
        nextLink = 'postsNextLink';
      }
      let emptyResult = '';
      let emptyResultBool;
      if (action.payload.data.length === 0) {
        if (newResult.type === 'posts') {
          emptyResult = 'emptyResultPosts';
          emptyResultBool = true;
        } else {
          emptyResult = 'emptyResultUsers';
          emptyResultBool = true;
        }
      } else if (newResult.type === 'posts') {
        emptyResult = 'emptyResultPosts';
        emptyResultBool = false;
      } else {
        emptyResult = 'emptyResultUsers';
        emptyResultBool = false;
      }
      return newState(state, {
        [newResult.type]: newResult.data,
        [nextLink]: action.payload.links.nextLink,
        [emptyResult]: emptyResultBool,
        query: decodeURIComponent(
          action.payload.query.replace(/[!"№;%:?*()]/g, ''),
        ),
        isLoading: false,
      });
    case NEXT_SEARCH:
      const nextResult = searchFactory(action.payload.data, state.type);
      let nextLinkResult = 'usersNextLink';
      if (state.type === 'posts') {
        nextLinkResult = 'postsNextLink';
      }
      return newState(state, {
        [state.type]: [...state[state.type], ...nextResult.data],
        [nextLinkResult]: action.payload.links.nextLink,
        isLoading: false,
      });
    case NEXT_PAGE_START:
      return newState(state, { isLoading: true });
    case FETCH_SEARCH_START:
      return newState(state, { isLoading: true });
    case CHANGE_INPUT_VALUE:
      return newState(state, { query: decodeURIComponent(action.payload) });
    case RESET_SEARCH:
      return newState(state, {
        posts: [],
        users: [],
        postsNextLink: '',
        usersNextLink: '',
        emptyResultPosts: false,
        emptyResultUsers: false,
      });
    default:
      return state;
  }
};

export default searchReducer;
