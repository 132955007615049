import {
  SETBROWSER,
  SETDEVICE,
  SETIPHONEX,
  TOGGLE_MODAL_LOGIN,
  TRIGGER_SNACK_BAR,
  REDIRECT_WITH_MODAL,
  REMOVE_TARGET,
  TOGGLE_RESTORE,
  SET_POST_EDIT,
  TRY_TO_CHANGE_PAGE,
  SET_PREV_ROUTE,
  TRIGGER_PRELOAD_POPUP,
  MY_ACCOUNT_CHANGED,
  TRY_TO_LOGOUT,
  SET_IS_PRODUCTION,
  SET_USER_ADBLOCK,
  TRIGGER_MESSENGER_POPUP,
  SETCOUNTRIES,
  SET_CURRENT_COUNTRY,
  SETREGIONS,
  SET_CURRENT_REGION,
  SET_APP_NEED_UPDATE,
  SET_PAYMENT_RESULT,
  SET_UNSUBSCRIBE_STEP,
  SET_ACHIEVEMENTS_MODAL,
  SET_VOTE_POPUP,
  SET_UNLOCKED_ACHIEVEMENT_OPENED,
  SET_UNLOCKED_ACHIEVEMENT_DATA,
  ADD_UNLOCKED_ACHIEVEMENT_DATA,
  SET_COUNTRY_MODAL_DISABLED,
  SET_LITTLESWIPE_ENABLED,
  SET_WALLET_COLLAPSED,
  SET_TOURNAMENTS_FEATURE_MODAL,
  showedTournamentVideoPopup, showCustomNotification, showedReferralCongratulationsPopup,
} from '../actionTypes';

export const setAppNeedUpdate = (isNeedUpdate) => ({
  type: SET_APP_NEED_UPDATE,
  payload: isNeedUpdate,
});
export const setCountries = (countries) => ({
  type: SETCOUNTRIES,
  payload: countries,
});
export const setCurrentCountry = (country) => ({
  type: SET_CURRENT_COUNTRY,
  payload: country,
});
export const setRegions = (regions) => ({ type: SETREGIONS, payload: regions });
export const setCurrentRegion = (region) => ({
  type: SET_CURRENT_REGION,
  payload: region,
});
export const setBrowser = (browser) => ({ type: SETBROWSER, payload: browser });
export const setProduction = () => ({ type: SET_IS_PRODUCTION });
export const setAdblock = () => ({ type: SET_USER_ADBLOCK });
export const myAccountChanged = (bool) => ({
  type: MY_ACCOUNT_CHANGED,
  payload: bool,
});

export const triggerMessengerPopup = (bool) => ({
  type: TRIGGER_MESSENGER_POPUP,
  payload: bool,
});

export const triggerPreloadPopup = (bool) => ({
  type: TRIGGER_PRELOAD_POPUP,
  payload: bool,
});

export const setPrevRoute = (route) => ({
  type: SET_PREV_ROUTE,
  payload: route,
});

export const setDevice = (device) => ({ type: SETDEVICE, payload: device });

export const setIphoneX = (iphoneX) => ({ type: SETIPHONEX, payload: iphoneX });
export const toggleModalLogin = (isOpen, target) => ({
  type: TOGGLE_MODAL_LOGIN,
  payload: { isOpen, target },
});

export const toggleRedirectModal = (value, target) => ({
  type: REDIRECT_WITH_MODAL,
  payload: { value, target },
});

export const removeTarget = () => ({ type: REMOVE_TARGET });

export const triggerSnackBar = (bool, message) => ({
  type: TRIGGER_SNACK_BAR,
  payload: { snackBar: bool, snackBarMessage: message },
});

export const toggleRestore = (bool) => ({
  type: TOGGLE_RESTORE,
  payload: bool,
});

export const setPostEdit = (value) => ({ type: SET_POST_EDIT, payload: value });

export const tryToChangePage = (link, logout) => ({
  type: TRY_TO_CHANGE_PAGE,
  payload: { link, logout },
});

export const tryToLogout = (tryTologout) => ({
  type: TRY_TO_LOGOUT,
  payload: tryTologout,
});

export const setPaymentResult = (payload) => ({
  type: SET_PAYMENT_RESULT,
  payload,
});

export const setUnsubscribeStep = (step) => ({
  type: SET_UNSUBSCRIBE_STEP,
  payload: step,
});

export const setAchievementsModal = (payload) => ({
  type: SET_ACHIEVEMENTS_MODAL,
  payload,
});

export const setVotePopup = (payload) => ({
  type: SET_VOTE_POPUP,
  payload,
});

export const setUnlockedAchievementsOpened = (payload) => ({
  type: SET_UNLOCKED_ACHIEVEMENT_OPENED,
  payload,
});

export const setUnlockedAchievementsData = (payload) => ({
  type: SET_UNLOCKED_ACHIEVEMENT_DATA,
  payload,
});

export const addUnlockedAchievementsData = (payload) => ({
  type: ADD_UNLOCKED_ACHIEVEMENT_DATA,
  payload,
});

export const setLittleswipeEnabled = (payload) => ({
  type: SET_LITTLESWIPE_ENABLED,
  payload,
});

export const setCountryModalDisabled = payload => ({
  type: SET_COUNTRY_MODAL_DISABLED,
  payload,
});

export const setWalletCollapsed = (payload) => ({
  type: SET_WALLET_COLLAPSED,
  payload,
});

export const setTournamentsFeatureModal = (payload) => ({
  type: SET_TOURNAMENTS_FEATURE_MODAL,
  payload,
});

export const setShowedTournamentVideoPopup = (payload) => ({
  type: showedTournamentVideoPopup,
  payload,
});

export const setShowCustomNotification = (payload) => ({
  type: showCustomNotification,
  payload,
});

export const setShowedReferralCongratulationsPopup = (payload) => ({
  type: showedReferralCongratulationsPopup,
  payload,
});
