import { newState } from '../utils';
import { REFERRAL_DATA } from '../constants/referral';

const initialState = {
  status: 'LOADING',
  data: {
    code: '',
    count_registrations: 0,
    countVotes: 0,
    image: null,
    nickname: null,
  },
};

const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFERRAL_DATA.SET_DATA:
      const data = newState(state, { ...action.payload });
      // localStorage.setItem('userData', JSON.stringify(data));
      return data;
    case REFERRAL_DATA.SET_STATUS:
      const data2 = newState(state, { ...state, status: action.payload });
      // localStorage.setItem('userData', JSON.stringify(data));
      return data2;
    // case LOGOUT:
      // localStorage.removeItem('userData');
      // return initialState;
    default:
      return state;
  }
};

export default referralReducer;
