import { updateUser } from 'src/actions/user';
import {
  ADD_UNLOCKED_ACHIEVEMENT_DATA,
  MY_ACCOUNT_CHANGED,
  REDIRECT_WITH_MODAL,
  REMOVE_TARGET,
  SET_ACHIEVEMENTS_MODAL,
  SET_APP_NEED_UPDATE,
  SET_CURRENT_COUNTRY,
  SET_CURRENT_REGION,
  SET_IS_PRODUCTION,
  SET_PAYMENT_RESULT,
  SET_POST_EDIT,
  SET_PREV_ROUTE,
  SET_LITTLESWIPE_ENABLED,
  SET_UNLOCKED_ACHIEVEMENT_DATA,
  SET_UNLOCKED_ACHIEVEMENT_OPENED,
  SET_USER_ADBLOCK,
  SET_VOTE_POPUP,
  SETBROWSER,
  SETCOUNTRIES,
  SETDEVICE,
  SETIPHONEX,
  SETREGIONS,
  SETSOURCES,
  TOGGLE_MODAL_LOGIN,
  TOGGLE_RESTORE,
  TRIGGER_MESSENGER_POPUP,
  TRIGGER_PRELOAD_POPUP,
  TRIGGER_SNACK_BAR,
  TRY_TO_CHANGE_PAGE,
  TRY_TO_LOGOUT,
  SET_UNSUBSCRIBE_STEP,
  SET_COUNTRY_MODAL_DISABLED,
  SET_WALLET_COLLAPSED,
  SET_TOURNAMENTS_FEATURE_MODAL, showedTournamentVideoPopup, showCustomNotification, showedReferralCongratulationsPopup,
} from '../actionTypes';
import { newState } from '../utils';

const initialState = {
  browser: '',
  device: '',
  iphoneX: '',
  sources: process.env.REACT_APP_SOURCES_URL,
  modalLoginIsOpen: false,
  snackBar: false,
  snackBarMessage: 'Error',
  target: '',
  redirectModal: false,
  restore: false,
  postIsEditing: false,
  redirectLink: '',
  redirectLogout: false,
  prevRoute: '',
  preloadPopup: false,
  myAccountIsChanged: false,
  toLogout: false,
  isProduction: false,
  isAdblock: false,
  showMessengerPopup: false,
  countriesList: [],
  currentCountry: null,
  regionsList: [],
  currentRegion: { id: 0, title: 'All states' },
  appNeedUpdate: false,
  paymentResult: null,
  achievementsModal: {
    opened: false,
    data: null,
  },
  unlockedAchievements: {
    opened: false,
    achievements: [],
  },
  votePopup: false,
  littleswipeEnabled: true,
  unsubscribeStep: 1,
  countryModalDisabled: false,
  walletCollapsed: false,
  tournamentsFeatureModal: false,
  is_showed_tournament_video_popup: false,
  showCustomNotification: false,
  is_showed_referral_congratulations_popup: false,
};

const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UNLOCKED_ACHIEVEMENT_OPENED:
      return newState(state, {
        unlockedAchievements: {
          ...state.unlockedAchievements,
          opened: action.payload,
        },
      });
    case SET_UNLOCKED_ACHIEVEMENT_DATA:
      return newState(state, {
        unlockedAchievements: {
          opened: state.unlockedAchievements.opened,
          achievements: action.payload,
        },
      });
    case ADD_UNLOCKED_ACHIEVEMENT_DATA:
      return newState(state, {
        unlockedAchievements: {
          opened: state.unlockedAchievements.opened,
          achievements: [
            action.payload,
            ...state.unlockedAchievements.achievements,
          ],
        },
      });
    case SETCOUNTRIES:
      return newState(state, {
        countriesList: action.payload,
      });
    case SET_ACHIEVEMENTS_MODAL:
      return newState(state, {
        achievementsModal: action.payload,
      });
    case SET_VOTE_POPUP:
      return newState(state, {
        votePopup: action.payload,
      });
    case SET_CURRENT_COUNTRY:
      localStorage.setItem('currentCountry', JSON.stringify(action.payload));
      return newState(state, {
        currentCountry: action.payload,
        appNeedUpdate: true,
        regionsList: [],
        currentRegion: { id: 0, title: 'All states' },
      });
    case SET_APP_NEED_UPDATE:
      return newState(state, {
        appNeedUpdate: action.payload,
      });
    case SETREGIONS:
      return newState(state, {
        regionsList: action.payload,
      });
    case SET_CURRENT_REGION:
      return newState(state, {
        currentRegion: action.payload,
      });
    case SETBROWSER:
      return newState(state, {
        browser: action.payload,
      });
    case SET_USER_ADBLOCK:
      return newState(state, {
        isAdblock: true,
      });
    case SET_IS_PRODUCTION:
      return newState(state, {
        isProduction: true,
      });
    case SETDEVICE:
      return newState(state, {
        device: action.payload,
      });
    case SETIPHONEX:
      return newState(state, {
        iphoneX: action.payload,
      });
    case SETSOURCES:
      return newState(state, {
        sources: action.payload,
      });
    case REMOVE_TARGET:
      return newState(state, {
        target: '',
      });
    case SET_PREV_ROUTE:
      return newState(state, {
        prevRoute: action.payload,
      });
    case TRY_TO_LOGOUT:
      return newState(state, {
        toLogout: action.payload,
      });
    case TOGGLE_MODAL_LOGIN:
      let targetLink = '';
      if (typeof action.payload.target !== 'undefined') targetLink = action.payload.target;
      else targetLink = state.target;
      return newState(state, {
        modalLoginIsOpen: action.payload.isOpen,
        preloadPopup: action.payload.isOpen,
        target: targetLink,
      });
    case REDIRECT_WITH_MODAL:
      let { target } = action.payload;
      if (!action.payload.value) target = state.target;
      return newState(state, {
        redirectModal: action.payload.value,
        target,
      });
    case TRIGGER_SNACK_BAR:
      return newState(state, {
        ...action.payload,
      });
    case TRIGGER_MESSENGER_POPUP:
      return newState(state, {
        showMessengerPopup: action.payload,
      });
    case TRIGGER_PRELOAD_POPUP:
      return newState(state, {
        preloadPopup: action.payload,
      });
    case TOGGLE_RESTORE:
      return newState(state, {
        restore: action.payload,
      });
    case SET_POST_EDIT:
      return newState(state, {
        postIsEditing: action.payload,
      });
    case TRY_TO_CHANGE_PAGE:
      return newState(state, {
        redirectLink: action.payload.link,
        redirectLogout: action.payload.logout,
      });
    case MY_ACCOUNT_CHANGED:
      return newState(state, {
        myAccountIsChanged: action.payload,
      });
    case SET_PAYMENT_RESULT:
      return newState(state, {
        paymentResult: action.payload,
      });
    case SET_LITTLESWIPE_ENABLED:
      return newState(state, {
        littleswipeEnabled: action.payload,
      });
    case SET_UNSUBSCRIBE_STEP:
      return newState(state, {
        unsubscribeStep: action.payload,
      });
    case SET_COUNTRY_MODAL_DISABLED:
      return newState(state, {
        countryModalDisabled: action.payload,
      });
    case SET_WALLET_COLLAPSED:
      return newState(state, {
        walletCollapsed: action.payload,
      });
    case SET_TOURNAMENTS_FEATURE_MODAL:
      return newState(state, {
        tournamentsFeatureModal: action.payload,
      });
    case showedTournamentVideoPopup:
      return newState(state, {
        is_showed_tournament_video_popup: action.payload,
      });
    case showCustomNotification:
      return newState(state, {
        showCustomNotification: action.payload,
      });
    case showedReferralCongratulationsPopup:
      return newState(state, {
        is_showed_referral_congratulations_popup: action.payload,
      });
    default:
      return state;
  }
};

export default postsReducer;
