import React from 'react';
import ReactSVG from 'react-svg';
import { defineMessages, injectIntl } from 'react-intl';
import Button from 'src/components/Form/Button';
import TournamentsFeature from 'src/static/svg/tournaments-feature.svg';
import cn from 'classnames';
import { patchSavePopupPreview } from 'src/modules/tournaments/api/tournament';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from 'src/actions/user';
import { setTournamentsFeatureModal } from 'src/actions/app';
import { useHistory } from 'react-router-dom';
import s from './TournamentsFeatureModal.module.scss';

const TournamentsFeatureModal = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const code = useSelector((state) => state.app.currentCountry.code.toLowerCase());

  const handleClick = (redirect) => () => {
    if (redirect) {
      history.push(`/${code}/tournaments/all_levels`);
    }

    dispatch(updateUser({
      is_showed_tournament_popup: true,
    }));
    patchSavePopupPreview();
    dispatch(setTournamentsFeatureModal(false));
  };

  return (
    <div className={s.wrapper}>
      <span className={s.new}>
        {formatMessage(messages.New)}
      </span>
      <svg width="145" height="145" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 145 145">
        <path d="M138.5 0l6.5 9h-31V0z" fill="#e35f32" />
        <path d="M0 138.5l9 6.5v-31H0z" fill="#e35f32" />
        <g><path d="M61.5 0L0 63v71L131 0h-22.5z" fill="var(--color-secondary)" /></g>
      </svg>
      <ReactSVG src={TournamentsFeature} className={s.decor} />
      <h3 className={s.title} dangerouslySetInnerHTML={{ __html: `${formatMessage(messages['Can your child<br> win a tournament'])}?` }} />
      <p className={s.description} dangerouslySetInnerHTML={{ __html: formatMessage(messages['Compete in daily tournaments and find out who has the cutest child.<br> No paid votes, the winner is decided by the Little Winner community!']) }} />
      <Button className={cn('big primary', s.playNow)} onClick={handleClick(true)}>
        {formatMessage(messages['Play now'])}
      </Button>
      <button onClick={handleClick(false)} type="button" className={s.noThanks}>
        {formatMessage(messages['No, thanks, next time'])}
      </button>
    </div>
  );
};

export default injectIntl(TournamentsFeatureModal);

const messages = defineMessages({
  'Can your child<br> win a tournament': {
    id: 'Can your child<br> win a tournament',
    defaultMessage: 'Can your child<br> win a tournament',
  },
  'Compete in daily tournaments and find out who has the cutest child.<br> No paid votes, the winner is decided by the Little Winner community!': {
    id: 'Compete in daily tournaments and find out who has the cutest child.<br> No paid votes, the winner is decided by the Little Winner community!',
    defaultMessage: 'Compete in daily tournaments and find out who has the cutest child.<br> No paid votes, the winner is decided by the Little Winner community!',
  },
  'Play now': {
    id: 'Play now',
    defaultMessage: 'Play now',
  },
  'No, thanks, next time': {
    id: 'No, thanks, next time',
    defaultMessage: 'No, thanks, next time',
  },
  New: {
    id: 'New',
    defaultMessage: 'New',
  },
});
